<template>
   <div 
     v-loading.fullscreen.lock="loading" 
     element-loading-text="请求数据中" 
     element-loading-background="rgba(255, 255, 255, 0.5)"
    >
     <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-bottom: 20px">
       <el-tab-pane label="全部" name="80">
        <el-select v-model="pageInfoAll.storageId" placeholder="采购仓库" class="el_select" clearable @change="find1">
            <el-option
              v-for="item in storageList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
        </el-select>
        <el-select v-model="pageInfoAll.supplierId" placeholder="供应商" class="el_select" clearable @change="find1">
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
        </el-select>
        <el-input v-model="pageInfoAll.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:10px" @keyup.enter.native = "find1" />
        <el-button type="primary" icon="el-icon-search" size="small" @click="find1">查询</el-button>
        <!-- 列表区域 -->
        <el-table
           :data="orderList1"
           :header-cell-style="{background:'#EFEFEF',textAlign:'center'}" 
           cell-style="background:#EAF9FF"
           style="margin-top: 30px; width: 100%"
           default-expand-all
           :span-method="arraySpanMethod1"
           >
             <el-table-column type="expand" align="center" width="60px">
               <template slot-scope="props" width="60px">
                 <div v-for="item in props.row.purchaseProductInfoRes" :key="item.id" class="outSide">
                   <div class="purchasePlanNo">
                     <span>{{item.purchasePlanNo}}</span>
                   </div>
                   <div class="commoditySku">
                     <span>{{item.commoditySku}}</span>
                   </div>
                   <div class="planPurchaseNumber">
                     <span>{{item.planPurchaseNumber}}</span>
                   </div>
                   <div class="supplierName">
                      <span>{{item.supplierName}}</span>
                   </div>
                   <div class="storageName">
                     <span>{{item.storageName}}</span>
                   </div>
                   <div class="showStatus">
                     <span v-if="item.status == 215" style="color: #b5322e">待提交</span>
                     <span v-if="item.status == 216" style="color: #b5762e">待审核</span>
                     <span v-if="item.status == 217" style="color: #3a80c2">待采购</span>
                     <span v-if="item.status == 218" style="color: green">已处理</span>
                     <span v-if="item.status == 219" style="color: #f56c6c">已作废</span>
                     <span v-if="item.status == 220" style="color: #e6a23c">已驳回</span>
                   </div>
                   <div class="tips">
                     <span>{{item.tips}}</span>
                   </div>
                   <div class="purchaseNo">
                     <span>{{item.purchaseNo}}</span>
                   </div>
                   <div class="Operation">
                     <el-link :underline="false" type="primary" @click="showDetail(item)">查看详情<i class="el-icon-view el-icon--right"></i>
                     </el-link>
                   </div>
                 </div>
               </template>
             </el-table-column>
             <el-table-column label="采购计划编号" align="center" :show-overflow-tooltip="true" width="300px">
               <template slot-scope="scope">
                  <span>创建人/创建时间：{{scope.row.createBy}}/{{scope.row.createTime}}</span>
               </template>
             </el-table-column>
             <el-table-column label="SKU信息" prop="commoditySku" align="left" width="260px">
               <template slot-scope="scope">
                  <el-tooltip :content="scope.row.tips" placement="top">
                    <span class='tipsInfo'>备注: {{scope.row.tips}}</span>
                  </el-tooltip>
                  <el-link type='primary' class="el-icon-edit exit" @click="openInfo(scope.row.id,scope.row.tips)"></el-link>
               </template>
             </el-table-column>
             <el-table-column label="计划采购量" prop="planPurchaseNumber" align="center" width="260px"></el-table-column>
             <el-table-column label="供应商" prop="supplierId" align="center" width="260px"></el-table-column>
             <el-table-column label="仓库" prop="storageId" align="center" width="260px"></el-table-column>
             <el-table-column label="状态" prop="status" align="center" width="260px"></el-table-column>
             <el-table-column label="备注" align="center" width="260px"></el-table-column>
             <el-table-column label="采购单编号" prop="purchaseNo" align="center" width="260px"></el-table-column>
             <el-table-column label="操作" align="center" min-width="150px"></el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination 
            @size-change="handleSizeChange1" 
            @current-change="handleCurrentChange1"
            layout="total, sizes, prev, pager, next, jumper" 
            :page-sizes="[10, 30, 50, 100]" 
            :total="total1"
            :page-size="pageInfoAll.pageSize" 
            :current-page="pageInfoAll.current" 
           >
        </el-pagination>

        <!-- 查看详情 -->
        <el-dialog
            width="50%"
            title="查看详情"
            :visible.sync="allDataVisible"
            :close-on-click-modal="false"
           >
           <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
             <span>采购计划编号: {{allDataOblect.purchasePlanNo}}</span>
           </el-row>
           <el-row style="margin-bottom: 50px" v-show="submitAuditFlag"> 
             <el-steps :active="submitAuditActive" align-center finish-status="success">
               <el-step title="创建" :description="submitAuditDescription"></el-step>
               <el-step title="审核" description="暂无"></el-step>
               <el-step title="已下单" description="暂无"></el-step>
             </el-steps>
           </el-row>
      
           <el-row style="margin-bottom: 50px" v-show="purchaseAllFlag"> 
             <el-steps :active="purchaseAllActive" align-center finish-status="success">
               <el-step title="创建" :description="submitAuditDescription"></el-step>
               <el-step title="审核" :description="purchaseAllDescription"></el-step>
               <el-step title="已下单" description="暂无"></el-step>
             </el-steps>
           </el-row>

           <el-row style="margin-bottom: 50px" v-show="processedAllFlag"> 
             <el-steps :active="processedAllActive" align-center finish-status="success">
               <el-step title="创建" :description="submitAuditDescription"></el-step>
               <el-step title="审核" :description="purchaseAllDescription"></el-step>
               <el-step title="已下单" :description="processedAllDescription"></el-step>
             </el-steps>
           </el-row>

           <el-row style="margin-bottom: 50px" v-show="invalidAllFlag"> 
             <el-steps :active="invalidAllActive" align-center finish-status="success">
               <el-step title="创建" :description="submitAuditDescription"></el-step>
               <el-step title="审核" :description="purchaseAllDescription"></el-step>
               <el-step title="作废" :description="invalidAllDescription"></el-step>
             </el-steps>
           </el-row>

           <el-row style="margin-bottom: 50px" v-show="rejectedAllFlag"> 
             <el-steps :active="rejectedAllActive" align-center finish-status="success">
               <el-step title="创建" :description="submitAuditDescription"></el-step>
               <el-step title="驳回" :description="rejectedAllDescription"></el-step>
             </el-steps>
           </el-row>
           <div class="look">
             <div class="nav">
               <el-divider content-position="left">基础信息</el-divider>
             </div>
              <div v-if="allDataOblect.productImagePath!=''" style="margin-left:25px" @click="amplifier(allDataOblect.productImagePath)">
                <el-image 
                  style="width: 150px; height: 150px"
                  :src="allDataOblect.productImagePath" 
                  :preview-src-list="srcList">
                </el-image>
              </div>
              <div v-else style="margin:10px 20px">暂无图像信息</div>
             <div class="fn">
               <div class="lable">SKU</div>
               <div class="value">{{allDataOblect.commoditySku}}</div>
             </div>
             <div class="fn">
               <div class="lable">商品名称</div>
               <div class="value">{{allDataOblect.commodityName}}</div>
             </div>
             <div class="fn">
               <div class="lable">计划采购仓库</div>
               <div class="value">{{allDataOblect.storageName}}</div>
             </div>
             <div class="fn">
               <div class="lable">计划采购量</div>
               <div class="value">{{allDataOblect.planPurchaseNumber}}</div>
             </div>
             <div class="fn">
               <div class="lable">关联采购单号</div>
               <div class="value">{{allDataOblect.purchaseNo}}</div>
             </div>
             <div class="fn">
               <div class="lable">计划供应商</div>
               <div class="value">{{allDataOblect.supplierName}}</div>
             </div>
             <div class="fn">
               <div class="lable">采购计划备注</div>
               <div class="value">{{allDataOblect.tips}}</div>
             </div>
           </div>
          <span slot="footer">
            <el-button @click="allDataVisible = false">取 消</el-button>
            <el-button type="primary" @click="allDataVisible = false">确 定</el-button>
          </span>
        </el-dialog>
       </el-tab-pane>

       <el-tab-pane label="待提交" name="81">
         <el-select v-model="pageInfoSubmit.storageId" placeholder="采购仓库" clearable class="el_select" @change="find1">
            <el-option
              v-for="item in storageList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
         </el-select>
         <el-select v-model="pageInfoSubmit.supplierId" placeholder="供应商" clearable class="el_select" @change="find1">
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
         </el-select>
         <el-input v-model="pageInfoSubmit.keyword" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:10px" @keyup.enter.native = "find1" />
         <el-button type="primary" icon="el-icon-search" size="small" @click="find2">查询</el-button>
         <el-button type="success" icon="el-icon-plus" size="small" @click="add2">创建采购计划</el-button>
         <el-button type="warning" icon="el-icon-warning" size="small" @click="batchAudit">批量提交审核</el-button>
         <el-button type="danger" icon="el-icon-delete" size="small"  @click="batchDele">批量删除</el-button>
         <!-- 列表区域 -->
         <el-table
          :data="orderList2"
          ref="orderList2"
          :header-cell-style="{background:'#EFEFEF',textAlign:'center'}" 
          cell-style="background: #EAF9FF"
          @selection-change="handleSelectionChanges2"
          style="margin-top: 30px"
          default-expand-all
          :span-method="arraySpanMethod"
           >
            <el-table-column type="selection" align="center" width="60px"></el-table-column>
            <el-table-column type="expand" align="center" width="60px">
              <template slot-scope="props">
                  <div v-for="item in props.row.purchaseProductInfoRes" :key="item.id" class="outSide">
                    <div class="showChecked">
                      <el-checkbox v-model="item.isChecked" @change="changeItem(props.row.id)"><br>
                      </el-checkbox>
                    </div>
                    <div class="purchasePlanNo">
                      <span class="item">{{ item.purchasePlanNo }}</span>
                    </div>
                    <div class="commoditySku">
                      <span>{{ item.commoditySku }}</span>
                    </div>
                    <div class="planPurchaseNumber">
                      <span>{{ item.planPurchaseNumber }}</span>
                    </div>
                    <div class="supplierName">
                      <span>{{ item.supplierName }}</span>
                    </div>
                    <div class="storageName">
                      <span>{{ item.storageName }}</span>
                    </div>
                    <div class="showStatus">
                      <span v-if="item.status == 215" style="color: #b5322e">待提交</span>
                    </div>
                    <div class="tips">
                      <span>{{ item.tips }}</span>
                    </div>
                    <div style="width: 260px; height: 50px; text-align:center; line-height: 50px;">
                      <span>{{item.volume}}</span>
                    </div>
                    <div style="width: 260px; height: 50px; text-align:center; line-height: 50px;">
                      <span>{{item.weight}}</span>
                    </div>
                    <div class="Operation">
                      <el-link :underline="false" icon="el-icon-warning" type="success" @click="submitAudit(item)">提交审核</el-link>
                      <el-link :underline="false" icon="el-icon-edit" type="primary" @click="submitEdit(item.id)" style="margin: 0 5px">编 辑</el-link>
                      <el-link :underline="false" icon="el-icon-delete" type="danger" @click="submitDele(item.id)" style="margin: 0 5px">删 除</el-link>
                      <el-link :underline="false" @click="submitDetail(item.id)">查 看<i class="el-icon-view el-icon--right"></i></el-link>
                    </div>
                  </div>
              </template>
             </el-table-column>
             <el-table-column label="采购计划编号" align="center" :show-overflow-tooltip="true" width="300px">
               <template slot-scope="scope">
                 <span>创建人/创建时间：{{ scope.row.createBy }}--{{ scope.row.createTime }}</span>
               </template>
             </el-table-column>
             <el-table-column label="SKU信息" prop="commoditySku" align="left" width="260px">
               <template slot-scope="scope">
                 <el-tooltip :content="scope.row.tips" placement="top">
                   <span class='tipsInfo'>备注: {{scope.row.tips}}</span>
                 </el-tooltip>
                 <el-link type='primary' class="el-icon-edit" @click="openInfo(scope.row.id,scope.row.tips)"></el-link>
               </template>
             </el-table-column>
             <el-table-column label="计划采购量" prop="planPurchaseNumber" align="center" width="260px"></el-table-column>
             <el-table-column label="供应商" prop="supplierId" align="center" width="260px"></el-table-column>
             <el-table-column label="仓库" prop="storageId" align="center" width="260px"></el-table-column>
             <el-table-column label="状态" prop="status" align="center" width="260px"></el-table-column>
             <el-table-column label="备注" align="center" width="260px"></el-table-column>
             <el-table-column label="体积(m3)" prop="volume" align="center" width="260px"></el-table-column>
             <el-table-column label="重量(kg)" prop="weight" align="center" width="260px"></el-table-column>
             <el-table-column label="操作" align="center" min-width="280"></el-table-column>
         </el-table>

         <!-- 分页区域 -->
         <el-pagination 
            @size-change="handleSizeChange2" 
            @current-change="handleCurrentChange2"
            layout="total, sizes, prev, pager, next, jumper" 
            :page-sizes="[10, 30, 50, 100]" 
            :total="total2"
            :page-size="pageInfoSubmit.pageSize" 
            :current-page="pageInfoSubmit.current" 
         ></el-pagination>
         <!-- 查看详情 -->
         <el-dialog
           title="查看详情"
           :visible.sync="detailVisible"
           width="50%"
           :close-on-click-modal="false"
          >
          <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
            <span>采购计划编号: {{detailList.purchasePlanNo}}</span>
          </el-row>
          <el-row style="margin-bottom: 50px"> 
            <el-steps :active="active" align-center finish-status="success">
              <el-step title="创建" :description="description"></el-step>
              <el-step title="审核" description="暂无"></el-step>
              <el-step title="已下单" description="暂无"></el-step>
            </el-steps>
          </el-row>
          
          <div class="look">
             <div class="nav">
               <el-divider content-position="left">基础信息</el-divider>
             </div>
             <!-- <div class="fn">
               <div class="lable">图片</div>
               <div class="value">{{detailList.productImagePath}}</div>
             </div> -->
             <div v-if="detailList.productImagePath!=''" style="margin-left:25px" @click="amplifier(detailList.productImagePath)">
                <el-image 
                  style="width: 150px; height: 150px"
                  :src="detailList.productImagePath" 
                  :preview-src-list="srcList">
                </el-image>
              </div>
              <div v-else style="margin:10px 20px">暂无图像信息</div>
             <div class="fn">
               <div class="lable">SKU</div>
               <div class="value">{{ detailList.commoditySku }}</div>
             </div>
             <div class="fn">
               <div class="lable">商品名称</div>
               <div class="value">{{ detailList.commodityName }}</div>
             </div>
             <div class="fn">
               <div class="lable">计划采购仓库</div>
               <div class="value">{{ detailList.storageName }}</div>
             </div>
             <div class="fn">
               <div class="lable">计划采购量</div>
               <div class="value">{{ detailList.planPurchaseNumber }}</div>
             </div>
             <div class="fn">
               <div class="lable">关联采购单号</div>
               <div class="value">{{ detailList.purchaseNo }}</div>
             </div>
             <div class="fn">
               <div class="lable">计划供应商</div>
               <div class="value">{{ detailList.supplierName }}</div>
             </div>
             <div class="fn">
               <div class="lable">采购计划备注</div>
               <div class="value">{{ detailList.tips }}</div>
             </div>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="detailVisible = false">取 消</el-button>
            <el-button type="primary" @click="detailVisible = false">确 定</el-button>
          </span>

         </el-dialog>

         <!-- 编辑页面 -->
         <el-dialog
          width="50%"
          title="编辑"
          :visible.sync="editVisible"
          :close-on-click-modal="false"
         >
           <el-table
            border
            :data="editDataList"
            :header-cell-style="{background:'#EFEFEF'}"
            >
             <el-table-column label="图片" align="center">
               <template slot-scope="scope">
                 <img :src="scope.row.productImagePath" alt="" style="width:80px; height: 60px">
               </template>
             </el-table-column>
             <el-table-column label="SKU" prop="commoditySku" align="center"></el-table-column>
             <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
             <el-table-column align="center">
               <template #header>
                 <span style="color: red">*</span>采购仓库
               </template>
               <template slot-scope="scope">
                 <el-select v-model="scope.row.storageId" placeholder="采购仓库" clearable>
                   <el-option label="已删除" value="0"></el-option>
                   <el-option
                     v-for="item in storageList"
                     :key="item.id"
                     :label="item.value"
                     :value="item.id"
                   ></el-option>
                 </el-select>
               </template>
             </el-table-column>
             <el-table-column label="计划采购量" align="center">
               <template #header>
                 <span style="color: red">*</span>计划采购量
               </template>
               <template slot-scope="scope">
                 <el-input v-model="scope.row.planPurchaseNumber" clearable></el-input>
               </template>
             </el-table-column>
             <el-table-column align="center">
               <template #header>
                 <span style="color: red">*</span>供应商
               </template>
               <template slot-scope="scope">
                <el-select v-model="scope.row.supplierId" placeholder="供应商" clearable>
                 <el-option
                  v-for="item in supplierList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                 ></el-option>
                </el-select>
               </template>
             </el-table-column>
             <el-table-column label="备注"  align="center">
               <template slot-scope="scope">
                 <el-input v-model="scope.row.tips" placeholder="备注" clearable></el-input>
               </template>
             </el-table-column>
           </el-table>

            <span slot="footer" class="dialog-footer">
              <el-button @click="editVisible = false">取 消</el-button>
              <el-button type="primary" @click="editBtn">确 定</el-button>
            </span>
         </el-dialog>

         <!-- 采购计划 -->
         <el-dialog
          title="采购计划"
          :visible.sync="procurementVisible"
          width="1200px"
          :before-close="handleClose">
           <el-row type="flex" class="row-bg">
             <el-col :span="4">
               SKU数：{{ selectArrLength }}
           </el-col>
             <el-col :span="4">
               计划采购总量：{{ totalNumber ? totalNumber : 0 }}
             </el-col>
           </el-row>
           <el-row type="flex" justify="start" style="margin-top: 25px">
             <el-col :span="2">
               采购计划备注:
             </el-col>
             <el-col :span="5">
               <el-input v-model="planTips" placeholder="请输入信息进行备注" clearable></el-input>
             </el-col>
             <el-col :span="2" style="text-align: center">
                交货日期:
             </el-col>
             <el-col :span="6">
               <el-date-picker
                 type="date"
                 v-model="deliveryTime"
                 value-format="yyyy-MM-dd HH:mm:ss"
                 format="yyyy 年 MM 月 dd 日"
                 placeholder="选择日期"
                 style="width: 220px">
               </el-date-picker>
             </el-col>
           </el-row>
           <el-row style="margin-top: 15px">
             <el-button type="primary" icon="el-icon-circle-plus" @click="chooseGoods">选择商品</el-button>
           </el-row>
          <!--列表区域 -->
          <el-table
            border 
            show-summary
            :summary-method="getSummaries"
            :data="procurementList"  
            :header-cell-style="{background:'#EFEFEF'}"
            style="margin-top: 30px"
           >
              <el-table-column label="图片" prop="productImagePath" align="center">
                <template slot-scope="scope">
                  <img :src="scope.row.productImagePath" alt="" style="width: 80px; height: 60px">
                </template>
              </el-table-column>
              <el-table-column label="SKU信息" prop="commoditySku" align="center"></el-table-column>
              <el-table-column label="采购仓库" align="center">
                <template slot-scope="scope">
                  <el-select 
                   v-model="scope.row.storageId" 
                   placeholder="请选择采购仓库"
                  >
                   <el-option
                     v-for="item in storageList"
                     :key="item.id"
                     :label="item.value"
                     :value="item.id"
                     @click.native="changePurchStore(scope.row, scope.$index)"
                   ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column align="center">
                <template #header>
                  <span style="color: red">*</span>计划采购量
                </template>
                <template slot-scope="scope">
                  <el-input
                   :min="0"
                   type="number" 
                   @change="asyncChange" 
                   placeholder="计划采购量"
                   v-model.number="scope.row.Number" 
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="供应商" align="center">
                <template slot-scope="scope">
                  <el-select 
                    v-model="scope.row.supplierId"
                    placeholder="请选择供应商"
                  >
                  <el-option
                   v-for="item in supplierList"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                   @click.native="onClick_select(scope.row)"
                  ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="体积(m3)" prop="volume" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.Number?scope.row.Number*scope.row.volume:0 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="重量(kg)" prop="weight" align="center"></el-table-column>
              <el-table-column label="备注" align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.tips" placeholder="备注"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px">
                <template slot-scope="scope">
                  <el-link type="danger" @click="handleDeleteClick(scope.$index)">
                  <div class="iconfont iconshanchu"></div>删除
                </el-link>
                </template>
              </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="procurementVisible = false">取 消</el-button>
            <el-button type="primary" @click="asyncConfirm">确 定</el-button>
          </span>
         </el-dialog>

         <!-- 添加商品 -->
         <el-dialog
          title="添加商品"
          width="40%"
          :visible.sync="goodsVisible"
          >
          <el-row class="searchStyle">
            <el-input 
              clearable 
              @clear="goodsSearch()"
              placeholder="请输入关键字" 
              v-model="pageInfoGoods.keywords" 
              style="width: 240px; margin-right: 20px"
            ></el-input>
            <el-button type="primary" icon="el-icon-search" @click="goodsSearch()">查询</el-button>
          </el-row>
          <!-- 列表区域 -->
          <el-table
            border
            height="445"
            :data="goodsLists"
            :header-cell-style="{background:'#EFEFEF'}"
            style="margin-top: 30px"
            @selection-change="handleSelectionGoods"
           >
            <el-table-column type="selection" :selectable="checkBoxDisable" align="center" width="60px"></el-table-column>
            <el-table-column label="图片" align="center">
              <template slot-scope="scope">
                <img :src="scope.row.productImagePath" alt="" style="width:80px; height: 60px">
              </template>
            </el-table-column>
            <el-table-column label="名称" prop="commodityName" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="SKU信息" prop="commoditySku" align="center"></el-table-column>
            <el-table-column label="良品量" prop="goodNumber" align="center">
              <template slot-scope="scope">
                <el-tooltip placement="top">
                  <div slot="content" v-for="item in scope.row.productStockInfoRes" :key="item.storageId" style="margin-top: 5px">
                    仓库名称：{{item.storageName}}<br/>
                    良品量：{{item.goodNumber}}<br/>
                    次品量：{{item.defectiveNumber}}<br/>
                  </div>
                  <span>{{ scope.row.goodNumber }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="次品量" prop="defectiveNumber" align="center">
              <template slot-scope="scope">
                <el-tooltip placement="top">
                  <div slot="content" v-for="item in scope.row.productStockInfoRes" :key="item.storageId" style="margin-top: 5px">
                    仓库名称：{{item.storageName}}<br/>
                    良品量：{{item.goodNumber}}<br/>
                    次品量：{{item.defectiveNumber}}<br/>
                  </div>
                  <span>{{ scope.row.defectiveNumber }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页区域 -->
          <el-pagination 
            @size-change="handleSizeChangeGoods" 
            @current-change="handleCurrentChangeGoods"
            layout="total, sizes, prev, pager, next, jumper" 
            :page-sizes="[10, 20, 30, 50]" 
            :total="totalGoods"
            :page-size="pageInfoGoods.pageSize" 
            :current-page="pageInfoGoods.current" 
          >
          </el-pagination>
          <span slot="footer" class="dialog-footer">
            <el-button @click="goodsVisible = false">取 消</el-button>
            <el-button type="primary" @click="addGoods">添 加</el-button>
          </span>
         </el-dialog>
       </el-tab-pane>
    
       <el-tab-pane label="待审核" name="82">
        <el-select v-model="pageInfoAudit.storageId" placeholder="采购仓库" class="el_select" clearable @change="find1">
          <el-option
            v-for="item in storageList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select v-model="pageInfoAudit.supplierId" placeholder="供应商" class="el_select" clearable @change="find1">
          <el-option
            v-for="item in supplierList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
         <el-input v-model="pageInfoAudit.keyword" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:10px"  @keyup.enter.native = "find1" />
         <el-button type="primary" icon="el-icon-search" size="small" @click="find3">查询</el-button>
         <el-button type="info" icon="el-icon-check" size="small" @click="batchPass">批量审核通过</el-button>
         <el-button type="danger" icon="el-icon-warning" size="small" @click="batchNotPass">批量审核不通过</el-button>

         <!-- 列表区域 -->
         <el-table
           :data="orderList3"
           ref="orderList3"
           :header-cell-style="{ background:'#EFEFEF', textAlign:'center' }" 
           cell-style="background: #EAF9FF"
           @selection-change="handleSelectionChanges3"
           style="margin-top: 30px"
           default-expand-all
           :span-method="arraySpanMethod"
           >
             <el-table-column type="selection" align="center" width="60px"></el-table-column>
             <el-table-column type="expand" width="60px"> 
               <template slot-scope="props">
                 <div v-for="item in props.row.purchaseProductInfoRes" :key="item.id" class="outSide">
                   <div class="showChecked">
                     <el-checkbox v-model="item.isChecked" @change="changeStatus(props.row.id)"><br></el-checkbox>
                   </div>
                   <div class="purchasePlanNo">
                     <span>{{ item.purchasePlanNo }}</span>
                   </div>
                   <div class="commoditySku">
                     <span>{{ item.commoditySku }}</span>
                   </div>
                   <div class="planPurchaseNumber">
                     <span>{{ item.planPurchaseNumber }}</span>
                   </div>
                   <div class="supplierName">
                     <span>{{ item.supplierName }}</span>
                   </div>
                   <div class="storageName">
                     <span>{{ item.storageName }}</span>
                   </div>
                   <div class="showStatus">
                     <span v-if="item.status == 216" style="color: #b5762e">待审核</span>
                   </div>
                   <div class="tips">
                     <span>{{ item.tips }}</span>
                   </div>
                  <div class="Operation">
                     <el-link :underline="false" icon="el-icon-check" type="primary" @click="approved(item)">审核通过</el-link>
                     <el-link :underline="false" icon="el-icon-warning" type="danger" @click="faileApproved(item)" style="padding: 0 8px">审核不通过</el-link>
                     <el-link :underline="false" type="info" @click="auditDetail(item)">查 看<i class="el-icon-view el-icon--right"></i></el-link>
                  </div>
                </div>
               </template>
             </el-table-column>
             <el-table-column label="采购计划编号" :show-overflow-tooltip="true" align="center" width="300px">
                <template slot-scope="scope">
                  <span>创建人/创建时间: {{scope.row.createBy}}--{{scope.row.createTime}}</span>
                </template>
             </el-table-column>
             <el-table-column label="SKU信息" prop="commoditySku" align="left" width="260px">
               <template slot-scope="scope">
                 <el-tooltip :content="scope.row.tips" placement="top">
                  <span class='tipsInfo'>备注: {{scope.row.tips}}</span>
                 </el-tooltip>
                 <el-link type='primary' class="el-icon-edit" @click="openInfo(scope.row.id,scope.row.tips)"></el-link>
               </template>
             </el-table-column>
             <el-table-column label="计划采购量" prop="planPurchaseNumber" align="center" width="260px"></el-table-column>
             <el-table-column label="供应商" prop="supplierId" align="center" width="260px"></el-table-column>
             <el-table-column label="仓库" prop="storageId" align="center" width="260px"></el-table-column>
             <el-table-column label="状态" prop="status" align="center" width="260px"></el-table-column>
             <el-table-column label="备注" align="center" width="260px"></el-table-column>
             <el-table-column label="操作" align="center" min-width="290px"></el-table-column>
         </el-table>

         <!-- 分页区域 -->
         <el-pagination 
           @size-change="handleSizeChange3" 
           @current-change="handleCurrentChange3"
           layout="total, sizes, prev, pager, next, jumper" 
           :page-sizes="[10, 30, 50, 100]" 
           :total="total3"
           :page-size="pageInfoAudit.pageSize" 
           :current-page="pageInfoAudit.current"
         >
         </el-pagination>

         <!-- 审核不通过 -->
         <el-dialog
           width="22%"
           title="审核不通过"
           :visible.sync="failureDialogVisible"
          >
           <el-form :rules="rules" ref="formRef" :model="form" label-width="105px">
             <el-form-item label="不通过原因" prop="reason">
              <el-input type="textarea" v-model="form.reason"></el-input>
             </el-form-item>
           </el-form>
           <span slot="footer" class="dialog-footer">
             <el-button @click="failureDialogVisible = false">取 消</el-button>
             <el-button type="primary" @click="asyncFail">确 定</el-button>
           </span>
         </el-dialog>
         <!-- 批量审核不通过 -->
         <el-dialog
           width="22%"
           title="批量审核不通过"
           :visible.sync="batchFailureDialogVisible"
          >
            <el-form :rules="batchRules" ref="batchFormRef" :model="batchForm" label-width="105px">
              <el-form-item label="不通过原因" prop="reason">
               <el-input type="textarea" v-model="batchForm.reason"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="batchFailureDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="asyncBatchFail">确 定</el-button>
            </span>
         </el-dialog>
         <!-- 查看详情 -->
         <el-dialog
           width="50%"
           title="查看详情"
           :visible.sync="toAuditVisible"
           :close-on-click-modal="false"
          >
            <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
              <span>采购计划编号: {{auditObj.purchasePlanNo}}</span>
            </el-row>
            <el-row style="margin-bottom: 50px"> 
              <el-steps :active="auditactive" align-center finish-status="success">
                <el-step title="创建" :description="description"></el-step>
                <el-step title="审核" description="暂无"></el-step>
                <el-step title="已下单" description="暂无"></el-step>
              </el-steps>
            </el-row>

            <div class="look">
              <div class="nav">
                <el-divider content-position="left">基础信息</el-divider>
              </div>
              <!-- <div class="fn">
                <div class="lable">图片</div>
                <div class="value">{{auditObj.productImagePath}}</div>
              </div> -->
              <div v-if="auditObj.productImagePath!=''" style="margin-left:25px" @click="amplifier(auditObj.productImagePath)">
                <el-image 
                  style="width: 150px; height: 150px"
                  :src="auditObj.productImagePath" 
                  :preview-src-list="srcList">
                </el-image>
              </div>
              <div v-else style="margin:10px 20px">暂无图像信息</div>
              <div class="fn">
                <div class="lable">SKU</div>
                <div class="value">{{auditObj.commoditySku}}</div>
              </div>
              <div class="fn">
                <div class="lable">商品名称</div>
                <div class="value">{{auditObj.commodityName}}</div>
              </div>
              <div class="fn">
                <div class="lable">计划采购仓库</div>
                <div class="value">{{auditObj.storageName}}</div>
              </div>
              <div class="fn">
                <div class="lable">计划采购量</div>
                <div class="value">{{auditObj.planPurchaseNumber}}</div>
              </div>
              <div class="fn">
                <div class="lable">关联采购单号</div>
                <div class="value">{{auditObj.purchaseNo}}</div>
              </div>
              <div class="fn">
                <div class="lable">计划供应商</div>
                <div class="value">{{auditObj.supplierName}}</div>
              </div>
              <div class="fn">
                <div class="lable">采购计划备注</div>
                <div class="value">{{auditObj.tips}}</div>
              </div>
            </div>

           <span slot="footer" class="dialog-footer">
             <el-button @click="toAuditVisible = false">取 消</el-button>
             <el-button type="primary" @click="toAuditVisible = false">确 定</el-button>
           </span>
         </el-dialog>
       </el-tab-pane>

       <el-tab-pane label="待采购" name="83">
         <el-select v-model="pageInfoProcurement.storageId" placeholder="采购仓库" class="el_select" clearable @change="find1">
           <el-option
             v-for="item in storageList"
             :key="item.id"
             :label="item.value"
             :value="item.id"
           >
           </el-option>
         </el-select>
         <el-select v-model="pageInfoProcurement.supplierId" placeholder="供应商" class="el_select" clearable @change="find1">
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
         </el-select>
         <el-input v-model="pageInfoProcurement.keyword" clearable placeholder="请输入关键字" style="width: 200px;margin-right:20px"  @keyup.enter.native = "find1" />
         <el-button type="primary" icon="el-icon-search" size="small" @click="find4">查询</el-button>
         <el-button type="primary" size="small" @click="batchProcureOrder">批量生成采购单</el-button>
         <!-- 列表区域 -->
         <el-table
           :data="orderList4"
           ref="orderList4"
           :header-cell-style="{background:'#EFEFEF',textAlign:'center'}" 
           cell-style="background:#EAF9FF"
           @selection-change="handleSelectionChanges4"
           style="margin-top: 30px"
           default-expand-all
           :span-method="arraySpanMethod"
           >
             <el-table-column type="selection" align="center" width="60px"></el-table-column>
             <el-table-column type="expand" align="center" width="60px">
               <template slot-scope="props">
                 <div v-for="item in props.row.purchaseProductInfoRes" :key="item.id" class="outSide">
                   <div class="showChecked">
                     <el-checkbox v-model="item.isChecked" @change="changeTest(props.row.id)"></el-checkbox>
                   </div>
                   <div class="purchasePlanNo">
                     <span class="item">{{item.purchasePlanNo}}</span>
                   </div>
                   <div class="commoditySku">
                     <span>{{item.commoditySku}}</span>
                   </div>
                   <div class="planPurchaseNumber">
                     <span>{{item.planPurchaseNumber}}</span>
                   </div>
                   <div class="supplierName">
                     <span>{{item.supplierName}}</span>
                   </div>
                   <div class="storageName">
                     <span>{{item.storageName}}</span>
                   </div>
                   <div class="showStatus">
                     <span v-if="item.status == 217" style="color: #3a80c2">待采购</span>
                   </div>
                   <div class="tips">
                     <span>{{item.tips}}</span>
                   </div>
                  <div class="Operation">
                    <el-link :underline="false" type="success" icon="el-icon-check" @click="generateOrder(item)">生成采购单</el-link>
                    <el-link :underline="false" type="danger" icon="el-icon-close" style="padding: 0 10px" @click="asyncInvalid(item)">作 废</el-link>
                    <el-link :underline="false" type="info" @click="procureDetail(item)">查 看<i class="el-icon-view el-icon--right"></i></el-link>
                  </div>
                 </div>
               </template>
             </el-table-column>

             <el-table-column label="采购计划编号" :show-overflow-tooltip="true" align="center" width="300px">
                <template slot-scope="scope">
                  <span>创建人/创建时间: {{scope.row.createBy}}/{{scope.row.createTime}}</span>
                </template>
             </el-table-column>
             <el-table-column label="SKU信息" prop="commoditySku" align="left" width="260px">
                <template slot-scope="scope">
                 <el-tooltip :content="scope.row.tips" placement="top">
                  <span class='tipsInfo'>备注: {{scope.row.tips}}</span>
                 </el-tooltip>
                 <el-link type='primary' class="el-icon-edit" @click="openInfo(scope.row.id,scope.row.tips)"></el-link>
               </template>
             </el-table-column>
             <el-table-column label="计划采购量" prop="planPurchaseNumber" align="center" width="260px"></el-table-column>
             <el-table-column label="供应商" prop="supplierId" align="center" width="260px"></el-table-column>
             <el-table-column label="仓库" prop="storageId" align="center" width="260px"></el-table-column>
             <el-table-column label="状态" prop="status" align="center" width="260px"></el-table-column>
             <el-table-column label="备注" align="center" width="260px"></el-table-column>
             <el-table-column label="操作" align="center" min-width="290px"></el-table-column>
         </el-table>
         <!-- 分页区域 -->
         <el-pagination 
           @size-change="handleSizeChange4" 
           @current-change="handleCurrentChange4"
           layout="total, sizes, prev, pager, next, jumper" 
           :page-sizes="[10, 30, 50, 100]" 
           :total="total4"
           :page-size="pageInfoProcurement.pageSize" 
           :current-page="pageInfoProcurement.current" 
           >
         </el-pagination>
         <!-- 作废 -->
         <el-dialog
          width="22%"
          title="作废采购计划"
          :visible.sync="invalidDialogVisible"
          >
           <el-form :rules="invalidRules" ref="invalidFormRef" :model="invalidForm" label-width="105px">
             <el-form-item label="作废原因" prop="reason">
              <el-input type="textarea" v-model="invalidForm.reason"></el-input>
             </el-form-item>
           </el-form>
           <span slot="footer" class="dialog-footer">
             <el-button @click="invalidDialogVisible = false">取 消</el-button>
             <el-button type="primary" @click="invalidBtn">确 定</el-button>
           </span>
         </el-dialog>

         <!-- 生成采购单1 -->
         <el-dialog
           width="1500px"
           title="生成采购单"
           :visible.sync="purchaseOrderVisible"
          >
            <el-row>
              <el-button type="primary" icon="el-icon-circle-plus" @click="addPurchasePlanBtn">添加采购计划</el-button>
            </el-row>
            <el-table
              border
              :data="purchaseOrderlist"
              :header-cell-style="{background:'#EFEFEF'}"
              style="margin-top: 30px; width: 100%"
             >
              <el-table-column label="采购计划" prop="purchasePlanNo" align="center" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="图片" prop="productImagePath" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.productImagePath!=''" class="demo-image__preview" @click="amplifier(scope.row.productImagePath)">
                    <el-image 
                      style="width: 100px; height: 100px"
                      :src="scope.row.productImagePath" 
                      :preview-src-list="srcList">
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="SKU" prop="commoditySku" align="center"></el-table-column>
              <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
              <el-table-column label="计划采购量" prop="planPurchaseNumber" align="center"></el-table-column>
              <el-table-column align="center">
                <template #header>
                  <span style="color: red">*</span>实际采购数量
                </template>
                <template slot-scope="scope">
                  <el-input v-model.number="scope.row.actualPurchaseNumber" clearable placeholder="实际采购数量"></el-input>
                </template>
              </el-table-column>
              <el-table-column align="center">
                <template #header>
                  <span style="color: red">*</span>采购单价(¥)
                </template>
                <template slot-scope="scope">
                  <el-input v-model="scope.row.purchaseUnitPrice" clearable placeholder="采购单价"></el-input>
                </template>
              </el-table-column>
              <el-table-column align="center">
                <template #header>
                  <span style="color: red">*</span>实际供应商
                </template>
                <template slot-scope="scope">
                   <el-select v-model="scope.row.actualSupplierId" placeholder="供应商" clearable>
                      <el-option
                        v-for="item in supplierList"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                   </el-select>
                </template>
              </el-table-column>
              <el-table-column align="center">
                <template #header>
                  <span style="color: red">*</span>采购仓库
                </template>
                <template slot-scope="scope">
                  <el-select v-model="scope.row.storageId" placeholder="采购仓库" clearable>
                   <el-option label="已删除" value="0"></el-option>
                   <el-option
                     v-for="item in storageList"
                     :key="item.id"
                     :label="item.value"
                     :value="item.id"
                   >
                   </el-option>
                 </el-select>
                </template>
              </el-table-column>
              <el-table-column label="采购计划备注" prop="tips" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-link type="danger" @click="handleDelete(scope.$index)">
                    <div class="iconfont iconshanchu"></div>删除
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
              <el-button @click="Close2">取消</el-button>
              <el-button type="primary" @click="nextBtn">下一步</el-button>
            </span>
         </el-dialog>

         <!-- 生成采购单2 -->
         <el-dialog
           title="生成采购单"
           :visible.sync="createPurchaseVisible"
           width="1200px"
           :before-close="Close"
          >
            <div v-for="(item, index) in purchaseOrderInforList" :key="index">
              <div>
                <el-divider content-position="left">采购单{{index+1}}</el-divider>
              </div>
              <el-row type="flex" justify="flex-start">
                <div class="lable_div" style="margin-right: 50px">
                  采购仓库: {{item.storageName}}
                </div>
                <div class="lable_div">
                  供应商: {{item.supplierName}}
                </div>
              </el-row>
               <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                 <div class="lable_div">
                   <p style="width: 65px; margin-top: 7px">运费: </p>
                   <el-input v-model="item.freight" placeholder="请填写运费"></el-input>
                 </div>
                 <div class="lable_div">
                   <p style="width:80px; margin-top: 7px; margin-left: 25px">运费分摊:</p>
                   <el-select v-model="item.freightShareType" placeholder="运费分摊" clearable>
                     <el-option
                       v-for="item in shareTypeList"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id"
                     >
                     </el-option>
                   </el-select>
                 </div>
                 <div class="lable_div">
                   <p style="width: 115px; margin-top: 7px; margin-left: 20px;">其他费用:</p>
                   <el-input v-model="item.otherFee" placeholder="请填写其他费用"></el-input>
                 </div>
               </el-row>

               <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                 <div class="lable_div">
                   <p style="width: 85px; margin-top: 7px;">物流: </p>
                   <el-select v-model="item.logisticsId" placeholder="物流" clearable>
                     <el-option
                       v-for="item in transferTypeList"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id"
                     >
                     </el-option>
                   </el-select>
                   <el-input style="margin-left: 15px" v-model="item.logisticsNo" placeholder="请填写物流单号"></el-input>
                 </div>
                 <div class="lable_div">
                   <p style="width: 110px; margin-top: 7px; margin-left: 20px">预计到货时间: </p>
                    <el-date-picker
                     v-model="item.expectArrivalTime"
                     type="date"
                     placeholder="请选择日期"
                     format="yyyy 年 MM 月 dd 日"
                     value-format="yyyy-MM-dd"
                     :picker-options="pickerOptions">
                    </el-date-picker>
                 </div>
               </el-row>

               <el-row type="flex" justify="flex-start">
                 <div class="lable_div">
                   <p style="width: 65px; margin-top: 7px">备注: </p>
                   <el-input v-model="item.tips" placeholder="请输入信息进行备注" clearable></el-input>
                 </div>
               </el-row>
               <div>
                 <el-divider content-position="left">商品信息</el-divider>
               </div>
               <el-table
                 border
                 :data="item.purchaseProductInfoRes"
                 :header-cell-style="{background:'#EFEFEF'}"
                 style="margin-top: 30px;"
                >
                  <el-table-column label="图片" prop="productImagePath" align="center">
                    <template slot-scope="scope">
                      <div v-if="scope.row.productImagePath!=''" class="demo-image__preview" @click="amplifier(scope.row.productImagePath)">
                        <el-image 
                          style="width: 100px; height: 100px"
                          :src="scope.row.productImagePath" 
                          :preview-src-list="srcList">
                        </el-image>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="SKU" prop="commoditySku" align="center"></el-table-column>
                  <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
                  <el-table-column label="采购量" prop="actualPurchaseNumber" align="center"></el-table-column>
                  <el-table-column label="采购单价" align="center">
                     <template slot-scope="scope">
                      <el-input v-model="scope.row.purchaseUnitPrice" placeholder="采购单价" clearable></el-input>
                     </template>
                  </el-table-column>
                  <el-table-column label="贷款总计" prop="productPriceTotal" align="center"></el-table-column>
               </el-table>
            </div>

            <span slot="footer" class="dialog-footer">
               <el-button @click="createPurchaseVisible = false">取 消</el-button>
               <el-button type="primary" @click="createPurchaseBtn">确 定</el-button>
            </span>
         </el-dialog>

         <!-- 添加采购计划 -->
         <el-dialog
           title="添加采购计划"
           :visible.sync="addPurchasVisible"
           width="1300px"
           >
             <el-row class="searchStyle" > 
               <el-input 
                 clearable 
                 @clear="clearInfo"
                 placeholder="请输入关键字" 
                 v-model="pageInfoPurchase.keywords" 
                 style="width: 240px; margin-right: 20px"
               ></el-input>
               <el-button type="primary" icon="el-icon-search" @click="purchaseSearch()">查询</el-button>
             </el-row>

             <el-table
               border
               stripe
               height="510"
               :data="purchaseLists"
               :header-cell-style="{background:'#EFEFEF'}"
               style="margin-top: 30px"
               @selection-change="handleSelectionPurchase"
              >
                <el-table-column type="selection" :selectable="checkBoxDisable2" align="center" width="60px"></el-table-column>
                <el-table-column label="计划编号" prop="purchasePlanNo" align="center"></el-table-column>
                <el-table-column label="图片" align="center">
                  <template slot-scope="scope">
                    <img :src="scope.row.productImagePath" alt="" style="width: 80px; height: 60px">
                  </template>
                </el-table-column>
                <el-table-column label="商品信息" prop="productName" align="center"></el-table-column>
                <el-table-column label="计划采购量" prop="planPurchaseNumber" align="center"></el-table-column>
                <el-table-column label="供应商" prop="supplierName" align="center"></el-table-column>
                <el-table-column label="仓库" prop="storageName" align="center"></el-table-column>
                <el-table-column label="备注" align="center"></el-table-column>
             </el-table>

             <!-- 分页区域 -->
             <el-pagination
               @size-change="handleSizeChangePurchase"
               @current-change="handleCurrentChangePurchase"
               layout="total, sizes, prev, pager, next, jumper"
               :page-sizes="[10, 20, 30, 50]"
               :total="totalPurchase"
               :page-size="pageInfoPurchase.pageSize" 
               :current-page="pageInfoPurchase.current" 
              >
             </el-pagination>
             <span slot="footer" class="dialog-footer">
               <el-button @click="addPurchasVisible = false">取 消</el-button>
               <el-button type="primary" @click="confirmAdd">确 定</el-button>
             </span>
         </el-dialog>

         <!-- 待采购详情 -->
         <el-dialog
           width="50%"
           title="查看详情"
           :visible.sync="toPurchaseVisible"
           :close-on-click-modal="false"
          >
            <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
              <span>采购计划编号: {{purchaseObj.purchasePlanNo}}</span>
            </el-row>
            <el-row style="margin-bottom: 50px"> 
              <el-steps :active="purchaseactive" align-center finish-status="success">
                <el-step title="创建" :description="purchasedDescription1"></el-step>
                <el-step title="审核" :description="purchasedDescription2"></el-step>
                <el-step title="已下单" description="暂无"></el-step>
              </el-steps>
            </el-row>

            <div class="look">
              <div class="nav">
                <el-divider content-position="left">基础信息</el-divider>
              </div>
              <!-- <div class="fn">
                <div class="lable">图片</div>
                <div class="value">{{purchaseObj.productImagePath}}</div>
              </div> -->
              <div v-if="purchaseObj.productImagePath!=''" style="margin-left:25px" @click="amplifier(purchaseObj.productImagePath)">
                <el-image 
                  style="width: 150px; height: 150px"
                  :src="purchaseObj.productImagePath" 
                  :preview-src-list="srcList">
                </el-image>
              </div>
              <div v-else style="margin:10px 20px">暂无图像信息</div>
              <div class="fn">
                <div class="lable">SKU</div>
                <div class="value">{{purchaseObj.commoditySku}}</div>
              </div>
              <div class="fn">
                <div class="lable">商品名称</div>
                <div class="value">{{purchaseObj.commodityName}}</div>
              </div>
              <div class="fn">
                <div class="lable">计划采购仓库</div>
                <div class="value">{{purchaseObj.storageName}}</div>
              </div>
              <div class="fn">
                <div class="lable">计划采购量</div>
                <div class="value">{{purchaseObj.planPurchaseNumber}}</div>
              </div>
              <div class="fn">
                <div class="lable">关联采购单号</div>
                <div class="value">{{purchaseObj.purchaseNo}}</div>
              </div>
              <div class="fn">
                <div class="lable">计划供应商</div>
                <div class="value">{{purchaseObj.supplierName}}</div>
              </div>
              <div class="fn">
                <div class="lable">采购计划备注</div>
                <div class="value">{{purchaseObj.tips}}</div>
              </div>
            </div>

           <span slot="footer" class="dialog-footer">
             <el-button @click="toPurchaseVisible = false">取 消</el-button>
             <el-button type="primary" @click="toPurchaseVisible = false">确 定</el-button>
           </span>
         </el-dialog>
       </el-tab-pane>

       <el-tab-pane label="已处理" name="84">
        <el-select v-model="pageInfoManage.storageId" placeholder="采购仓库" class="el_select" clearable @change="find1">
            <el-option
              v-for="item in storageList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
        </el-select>
        <el-select v-model="pageInfoManage.supplierId" placeholder="供应商" class="el_select" clearable @change="find1">
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
        </el-select>
        <el-input v-model="pageInfoManage.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:20px"  @keyup.enter.native = "find1" />
        <el-button type="primary" icon="el-icon-search" size="small" @click="find5">查询</el-button>

         <!-- 列表区域 -->
         <el-table
           :data="orderList5"
           cell-style="background:#EAF9FF"
           :header-cell-style="{background:'#EFEFEF',textAlign:'center'}" 
           style="margin-top: 30px"
           default-expand-all
           :span-method="arraySpanMethod1"
           >
             <el-table-column type="expand" align="center" width="60px">
               <template slot-scope="props">
                <div v-for="item in props.row.purchaseProductInfoRes" :key="item.id" class="outSide">
                  <div class="purchasePlanNo">
                    <span>{{item.purchasePlanNo}}</span>
                  </div>
                  <div class="commoditySku">
                    <span>{{item.commoditySku}}</span>
                  </div>
                  <div class="planPurchaseNumber">
                    <span>{{item.planPurchaseNumber}}</span>
                  </div>
                  <div class="supplierName">  
                    <span>{{item.supplierName}}</span>
                  </div>
                  <div class="storageName">
                    <span>{{item.storageName}}</span>
                  </div>
                  <div class="showStatus">
                    <span v-if="item.status == 218" style="color: green">已处理</span>
                  </div>
                  <div class="tips">
                    <span>{{item.tips}}</span>
                  </div>
                  <div class="purchaseNo">
                    <span>{{item.purchaseNo}}</span>
                  </div>
                  <div class="Operation">
                     <el-link  type="primary"  @click="manageDetail(item)" :underline="false">查看详情<i class="el-icon-view el-icon--right"></i>
                    </el-link>
                  </div>
                </div>
               </template>
             </el-table-column>
  
             <el-table-column label="采购计划编号" :show-overflow-tooltip="true" align="center" width="300px">
               <template slot-scope="scope">
                 <span>创建人/创建时间: {{scope.row.createBy}}/{{scope.row.createTime}}</span>
               </template>
             </el-table-column>
             <el-table-column label="SKU信息" prop="commoditySku" align="left" width="260px">
               <template slot-scope="scope">
                 <el-tooltip :content="scope.row.tips" placement="top">
                    <span class='tipsInfo'>备注: {{scope.row.tips}}</span>
                  </el-tooltip>
                  <el-link style="display:inline-block" type='primary' class="el-icon-edit exit" @click="openInfo(scope.row.id,scope.row.tips)"></el-link>
               </template>
             </el-table-column>
             <el-table-column label="计划采购量" prop="purchasePlanNo" align="center" width="260px"></el-table-column>
             <el-table-column label="供应商" prop="supplierId" align="center" width="260px"></el-table-column>
             <el-table-column label="仓库" prop="storageId" align="center" width="260px"></el-table-column>
             <el-table-column label="状态" prop="status" align="center" width="260px"></el-table-column>
             <el-table-column label="备注" align="center" width="260px"></el-table-column>
             <el-table-column label="采购编号" prop="purchaseNo" align="center" width="260px"></el-table-column>
             <el-table-column label="操作" align="center" min-width="120px"></el-table-column>
         </el-table>

         <!-- 分页区域 -->
         <el-pagination 
           @size-change="handleSizeChange5" 
           @current-change="handleCurrentChange5"
           layout="total, sizes, prev, pager, next, jumper" 
           :page-sizes="[10, 30, 50, 100]" 
           :total="total5"
           :page-size="pageInfoManage.pageSize" 
           :current-page="pageInfoManage.current" 
          >
         </el-pagination>

         <!-- 已处理详情 -->
         <el-dialog
           width="50%"
           title="查看详情"
           :visible.sync="toManageVisible"
           :close-on-click-modal="false"
          >
             <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
               <span>采购计划编号: {{ManageObject.purchasePlanNo}}</span>
             </el-row>
             <el-row style="margin-bottom: 50px">
                <el-steps :active="Manageactive" align-center finish-status="success">
                  <el-step title="创建" :description="ManageDescription1"></el-step>
                  <el-step title="审核" :description="ManageDescription2"></el-step>
                  <el-step title="已下单" :description="ManageDescription3"></el-step>
                </el-steps>
             </el-row>

              <div class="look">
                <div class="nav">
                  <el-divider content-position="left">基础信息</el-divider>
                </div>
                <!-- <div class="fn">
                  <div class="lable">图片</div>
                  <div class="value">{{ManageObject.productImagePath}}</div>
                </div> -->
                <div v-if="ManageObject.productImagePath!=''" style="margin-left:25px" @click="amplifier(ManageObject.productImagePath)">
                <el-image 
                  style="width: 150px; height: 150px"
                  :src="ManageObject.productImagePath" 
                  :preview-src-list="srcList">
                </el-image>
                </div>
                <div v-else style="margin:10px 20px">暂无图像信息</div>
                <div class="fn">
                  <div class="lable">SKU</div>
                  <div class="value">{{ManageObject.commoditySku}}</div>
                </div>
                <div class="fn">
                  <div class="lable">属性</div>
                  <div class="value">{{ManageObject.commodityName}}</div>
                </div>
                <div class="fn">
                  <div class="lable">计划采购仓库</div>
                  <div class="value">{{ManageObject.storageName}}</div>
                </div>
                <div class="fn">
                  <div class="lable">计划采购量</div>
                  <div class="value">{{ManageObject.planPurchaseNumber}}</div>
                </div>
                <div class="fn">
                  <div class="lable">关联采购单号</div>
                  <div class="value">{{ManageObject.purchaseNo}}</div>
                </div>
                <div class="fn">
                  <div class="lable">计划供应商</div>
                  <div class="value">{{ManageObject.supplierName}}</div>
                </div>
                <div class="fn">
                  <div class="lable">采购计划备注</div>
                  <div class="value">{{ManageObject.tips}}</div>
                </div>
              </div>

              <span slot="footer" class="dialog-footer">
                <el-button @click="toManageVisible = false">取 消</el-button>
                <el-button type="primary" @click="toManageVisible = false">确 定</el-button>
              </span>
         </el-dialog>
       </el-tab-pane>

       <el-tab-pane label="已作废" name="85">
          <el-select clearable v-model="pageInfoInvalid.storageId" placeholder="采购仓库" class="el_select" @change="find1">
            <el-option
              v-for="item in storageList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select clearable v-model="pageInfoInvalid.supplierId" placeholder="供应商" class="el_select" @change="find1">
             <el-option
               v-for="item in supplierList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
             >
             </el-option>
          </el-select>
          <el-input v-model="pageInfoInvalid.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:20px"  @keyup.enter.native = "find1" />
          <el-button type="primary" icon="el-icon-search" size="small" @click="find6">查询</el-button>
         <!-- 列表区域 -->
         <el-table
           :data="orderList6"
           :header-cell-style="{background:'#EFEFEF',textAlign:'center'}" 
           cell-style="background:#EAF9FF"
           :span-method="arraySpanMethod1"
           style="margin-top: 30px"
           default-expand-all
           >
             <el-table-column type="expand" align="center" width="60px">
               <template slot-scope="props">
                 <div v-for="item in props.row.purchaseProductInfoRes" :key="item.id" class="outSide">
                   <div class="purchasePlanNo">
                     <span>{{item.purchasePlanNo}}</span>
                   </div>
                   <div class="commoditySku">
                     <span>{{item.commoditySku}}</span>
                   </div>
                   <div class="planPurchaseNumber">
                     <span>{{item.planPurchaseNumber}}</span>
                   </div>
                   <div class="supplierName">
                      <span>{{item.supplierName}}</span>
                   </div>
                   <div class="storageName">
                     <span>{{item.storageName}}</span>
                   </div>
                   <div class="showStatus">
                     <span v-if="item.status == 219" style="color: #f56c6c">已作废</span>
                   </div>
                   <div class="tips">
                     <span>{{item.tips}}</span>
                   </div>
                   <div class="Operation">
                     <el-link type="primary" @click="invalidDetail(item)" :underline="false">查看详情<i class="el-icon-view el-icon--right"></i>
                     </el-link>
                   </div>
                 </div>
               </template>
             </el-table-column>

             <el-table-column label="采购计划编号" :show-overflow-tooltip="true" align="center" width="260px">
               <template slot-scope="scope">
                 <span>创建人/创建时间: {{scope.row.createBy}}/{{scope.row.createTime}}</span>
               </template>
             </el-table-column>
             <el-table-column label="SKU信息" align="left" width="260px">
               <template slot-scope="scope">
                 <el-tooltip :content="scope.row.tips" placement="top">
                    <span class='tipsInfo'>备注: {{scope.row.tips}}</span>
                  </el-tooltip>
                  <el-link style="display:inline-block" type='primary' class="el-icon-edit exit" @click="openInfo(scope.row.id,scope.row.tips)"></el-link>
               </template>
             </el-table-column>
             <el-table-column label="计划采购量" prop="purchasePlanNo" align="center" width="260px"></el-table-column>
             <el-table-column label="供应商" prop="supplierId" align="center" width="260px"></el-table-column>
             <el-table-column label="仓库" prop="storageId" align="center" width="260px"></el-table-column>
             <el-table-column label="状态" prop="status" align="center" width="260px"></el-table-column>
             <el-table-column label="备注" align="center" width="260px"></el-table-column>
             <el-table-column label="操作" align="center" min-width="150px"></el-table-column>
         </el-table>

         <!-- 分页区域 -->
         <el-pagination 
           @size-change="handleSizeChange6" 
           @current-change="handleCurrentChange6"
           layout="total, sizes, prev, pager, next, jumper" 
           :page-sizes="[10, 30, 50, 100]" 
           :total="total6"
           :page-size="pageInfoInvalid.pageSize" 
           :current-page="pageInfoInvalid.current" 
         >
         </el-pagination>

         <!-- 已作废详情 -->
         <el-dialog
           width="50%"
           title="查看详情"
           :visible.sync="toInvalidVisible"
           :close-on-click-modal="false"
          >
            <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
              <span>采购计划编号: {{InvalidObject.purchasePlanNo}}</span>
            </el-row>
            <el-row style="margin-bottom: 50px">
              <el-steps :active="Invalidactive" align-center finish-status="success">
                <el-step title="创建" :description="InvalidDescription1"></el-step>
                <el-step title="审核" :description="InvalidDescription2"></el-step>
                <el-step title="作废" :description="InvalidDescription3"></el-step>
              </el-steps>
            </el-row>
            <div style="color:red;margin-left:20px">* 作废原因：{{cancelReason}}</div>

             <div class="look">
                <div class="nav">
                  <el-divider content-position="left">基础信息</el-divider>
                </div>
                <!-- <div class="fn">
                  <div class="lable">图片</div>
                  <div class="value">{{InvalidObject.productImagePath}}</div>
                </div> -->
                <div v-if="InvalidObject.productImagePath!=''" style="margin-left:25px" @click="amplifier(InvalidObject.productImagePath)">
                <el-image 
                  style="width: 150px; height: 150px"
                  :src="InvalidObject.productImagePath" 
                  :preview-src-list="srcList">
                </el-image>
                </div>
                <div v-else style="margin:10px 20px">暂无图像信息</div>
                <div class="fn">
                  <div class="lable">SKU</div>
                  <div class="value">{{InvalidObject.commoditySku}}</div>
                </div>
                <div class="fn">
                  <div class="lable">属性</div>
                  <div class="value">{{InvalidObject.commodityName}}</div>
                </div>
                <div class="fn">
                  <div class="lable">计划采购仓库</div>
                  <div class="value">{{InvalidObject.storageName}}</div>
                </div>
                <div class="fn">
                  <div class="lable">计划采购量</div>
                  <div class="value">{{InvalidObject.planPurchaseNumber}}</div>
                </div>
                <div class="fn">
                  <div class="lable">关联采购单号</div>
                  <div class="value">{{InvalidObject.purchaseNo}}</div>
                </div>
                <div class="fn">
                  <div class="lable">计划供应商</div>
                  <div class="value">{{InvalidObject.supplierName}}</div>
                </div>
                <div class="fn">
                  <div class="lable">采购计划备注</div>
                  <div class="value">{{InvalidObject.tips}}</div>
                </div>
             </div>

             <span slot="footer" class="dialog-footer">
               <el-button @click="toInvalidVisible = false">取 消</el-button>
               <el-button type="primary" @click="toInvalidVisible = false">确 定</el-button>
             </span>
         </el-dialog>
       </el-tab-pane>

       <el-tab-pane label="已驳回" name="86">
          <el-select v-model="pageInforJected.storageId" placeholder="采购仓库" class="el_select" clearable @change="find1">
            <el-option
              v-for="item in storageList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select v-model="pageInforJected.supplierId" placeholder="供应商" class="el_select" clearable @change="find1">
             <el-option
               v-for="item in supplierList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
             >
             </el-option>
          </el-select>
          <el-input v-model="pageInforJected.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:20px"  @keyup.enter.native = "find1" />
          <el-button type="primary" icon="el-icon-search" size="small" @click="find7">查询</el-button>

         <!-- 列表区域 -->
         <el-table
           :data="orderList7"
           :header-cell-style="{background:'#EFEFEF',textAlign:'center'}" 
           :span-method="arraySpanMethod1"
           cell-style="background:#EAF9FF"
           style="margin-top: 30px"
           default-expand-all
           >
             <el-table-column type="expand" align="center" width="60px">
               <template slot-scope="props">
                 <div v-for="item in props.row.purchaseProductInfoRes" :key="item.id" class="outSide">
                   <div class="purchasePlanNo">
                     <span>{{ item.purchasePlanNo }}</span>
                   </div>
                   <div class="commoditySku">
                     <span>{{ item.commoditySku }}</span>
                   </div>
                   <div class="planPurchaseNumber">
                     <span>{{ item.planPurchaseNumber }}</span>
                   </div>
                   <div class="supplierName">
                      <span>{{ item.supplierName }}</span>
                   </div>
                   <div class="storageName">
                     <span>{{ item.storageName }}</span>
                   </div>
                   <div class="showStatus">
                     <span v-if="item.status == 220" style="color: #e6a23c">已驳回</span>
                   </div>
                   <div class="tips">
                     <span>{{ item.tips }}</span>
                   </div>
                   <div class="Operation">
                     <el-link type="primary" @click="rejectedDetail(item)" :underline="false">查看详情<i class="el-icon-view el-icon--right"></i>
                     </el-link>
                   </div>
                 </div>
               </template>
             </el-table-column>

             <el-table-column label="采购计划编号" :show-overflow-tooltip="true" align="center" width="300px">
                <template slot-scope="scope">
                  <span>创建人/创建时间: {{scope.row.createBy}}/{{scope.row.createTime}}</span>
                </template>
             </el-table-column>
             <el-table-column label="SKU信息" prop="commoditySku" align="left" width="260px">
                <template slot-scope="scope">
                  <el-tooltip :content="scope.row.tips" placement="top">
                    <span class='tipsInfo'>备注: {{scope.row.tips}}</span>
                  </el-tooltip>
                  <el-link style="display:inline-block" type='primary' class="el-icon-edit exit" @click="openInfo(scope.row.id,scope.row.tips)"></el-link>
                </template>
             </el-table-column>
             <el-table-column label="计划采购量" prop="purchasePlanNo" align="center" width="260px"></el-table-column>
             <el-table-column label="供应商" prop="supplierId" align="center" width="260px"></el-table-column>
             <el-table-column label="仓库" prop="storageId" align="center" width="260px"></el-table-column>
             <el-table-column label="状态" prop="status" align="center" width="260px"></el-table-column>
             <el-table-column label="备注" align="center" width="260px"></el-table-column>
             <el-table-column label="操作" align="center" min-width="150px"></el-table-column>
         </el-table>

         <!-- 分页区域 -->
         <el-pagination 
           @size-change="handleSizeChange7" 
           @current-change="handleCurrentChange7"
           layout="total, sizes, prev, pager, next, jumper" 
           :page-sizes="[10, 30, 50, 100]" 
           :total="total7"
           :page-size="pageInforJected.pageSize" 
           :current-page="pageInforJected.current" 
          >
         </el-pagination>

        <!-- 已驳回详情 -->
        <el-dialog
          width="50%"
          title="查看详情"
          :visible.sync="toRejectVisible"
          :close-on-click-modal="false"
         >
            <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
              <span>采购计划编号: {{RejectObject.purchasePlanNo}}</span>
            </el-row>

            <el-row style="margin-bottom: 50px">
              <el-steps :active="Rejectactive" align-center finish-status="success">
                <el-step title="创建" :description="RejectDescription1"></el-step>
                <el-step title="驳回" :description="RejectDescription2"></el-step>
              </el-steps>
            </el-row>
            <div style="color:red;margin-left:20px">* 驳回原因：{{cancelReason}}</div>

            <div class="look">
               <div class="nav">
                 <el-divider content-position="left">基础信息</el-divider>
               </div>
               <!-- <div class="fn">
                 <div class="lable">图片</div>
                 <div class="value">{{RejectObject.productImagePath}}</div>
               </div> -->
               <div v-if="RejectObject.productImagePath!=''" style="margin-left:25px" @click="amplifier(RejectObject.productImagePath)">
                <el-image 
                  style="width: 150px; height: 150px"
                  :src="RejectObject.productImagePath" 
                  :preview-src-list="srcList">
                </el-image>
              </div>
              <div v-else style="margin:10px 20px">暂无图像信息</div>
               <div class="fn">
                 <div class="lable">SKU</div>
                 <div class="value">{{RejectObject.commoditySku}}</div>
               </div>
               <div class="fn">
                 <div class="lable">属性</div>
                 <div class="value">{{RejectObject.commodityName}}</div>
               </div>
               <div class="fn">
                 <div class="lable">计划采购仓库</div>
                 <div class="value">{{RejectObject.storageName}}</div>
               </div>
               <div class="fn">
                 <div class="lable">计划采购量</div>
                 <div class="value">{{RejectObject.planPurchaseNumber}}</div>
               </div>
               <div class="fn">
                 <div class="lable">关联采购单号</div>
                 <div class="value">{{RejectObject.purchaseNo}}</div>
               </div>
               <div class="fn">
                 <div class="lable">计划供应商</div>
                 <div class="value">{{RejectObject.supplierName}}</div>
               </div>
               <div class="fn">
                 <div class="lable">采购计划备注</div>
                 <div class="value">{{RejectObject.tips}}</div>
               </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="toRejectVisible = false">取 消</el-button>
              <el-button type="primary" @click="toRejectVisible = false">确 定</el-button>
            </span>
        </el-dialog>
       </el-tab-pane>
     </el-tabs>
   </div>
</template>

<script>
 import {
    getlistPage,
    getPageInit,
    getProductListPage,
    addPurchasePlan,
    deletePurchasePlan,
    getBatchUpdate,
    getMoreDetail,
    getUpdateInit,
    editPurchasePlan,
    addPurchaseInit,
    purchasePlanCreateList,
    batchDeletePlan,
    updataTips
 }from '@/api/procurementManage/procurementPlan.js'
 import {
   getAddInit,
   purchaseAddInit,
   addPurchase,
 }from '@/api/procurementManage/procurementOrder.js'
  export default {
    data() {
      return {
       pickerOptions: {
        disabledDate(time) {
          return time.getTime() < (Date.now() - 3600 * 1000 * 24);
        }
       },
       id:'',
       cancelReason:'',//作废原因
       srcList:[],
       activeName:'80',
       orderList1: [],
       orderList2: [],
       orderList3: [],
       orderList4: [],
       orderList5: [],
       orderList6: [],
       orderList7: [],
       storageList: [],
       supplierList: [],
       procurementList: [],
       goodsLists: [],
       selectArr: [],
       editDataList: [],
       currentRow: [],
       purchaseOrderlist: [],
       purchaseLists: [],
       productInfoList: [],
       shareTypeList: [],
       transferTypeList: [],
       packageList: [],
       purchaseOrderDataList: [],
       purchaseOrderInforList: [],
       selectArrayList: [],
       currentSelectlist: [],
       currentSelectlistSubmit: [],
       currentSelectlistAudit: [],
       total1: 0,  // 数据总量
       total2: 0,
       total3: 0,
       total4: 0,
       total5: 0,
       total6: 0,
       total7: 0,
       totalGoods: 0,
       planTips: "",
       keywords: "",
       invalidId: "",
       selectArrLength: 0,
       active: "",
       auditactive: "",
       purchaseactive: "",
       Manageactive: "",
       Invalidactive: "",
       Rejectactive: "",
       submitAuditActive: "",
       purchaseAllActive: "",
       processedAllActive: "",
       invalidAllActive: "",
       rejectedAllActive: "",
       description: "",
       purchasedDescription1: "",
       purchasedDescription2: "",
       ManageDescription1: "",
       ManageDescription2: "",
       ManageDescription3: "",
       InvalidDescription1: "",
       InvalidDescription2: "",
       InvalidDescription3: "",
       RejectDescription1: "",
       RejectDescription2: "",
       submitAuditDescription: "",
       purchaseAllDescription: "",
       processedAllDescription: "",
       invalidAllDescription: "",
       rejectedAllDescription: "",
       totalNumber: "",
       notThroughId: "",
       notThroughStatus: "",
       totalPurchase: "",
       procurementVisible: false,
       goodsVisible: false,
       detailVisible: false,
       editVisible: false,
       failureDialogVisible: false,
       batchFailureDialogVisible: false,
       toAuditVisible: false,
       invalidDialogVisible: false, 
       purchaseOrderVisible: false,
       addPurchasVisible: false,  
       createPurchaseVisible: false,  
       toPurchaseVisible: false,
       toManageVisible: false,
       toInvalidVisible: false,  // 作废详情
       toRejectVisible: false,  // 驳回详情
       allDataVisible: false,  // 所有数据
       submitAuditFlag: false,
       purchaseAllFlag: false,
       processedAllFlag: false,
       rejectedAllFlag: false,
       invalidAllFlag: false,
       detailList: {},
       detailobj: {},
       auditObj: {},
       purchaseObj: {},
       ManageObject: {},
       InvalidObject: {},
       RejectObject: {},
       allDataOblect: {},
       form: {
         reason: "",  // 未通过原因
       },
       batchForm: {
         reason: "",  // 未通过原因
       },
       invalidForm: {
         reason: "",  // 作废原因
       },
       rules: {
         reason: [
           { required: true, message: "请填写不通过原因", trigger: "blur" }
         ],
       },
       batchRules: {
         reason:[
           { required: true, message: "请填写不通过原因", trigger: "blur" }
         ]
       },
       invalidRules: {
         reason: [
           { required: true, message: "请填写作废原因", trigger: "blur" }
         ]
       },
       pageInfoAll: {
         storageId: "",
         supplierId: "",
         keywords: "",
         current: 1,
         pageSize: 10
       },
       //  待提交
       pageInfoSubmit: {
         storageId: "",
         supplierId: "",
         keyword: "",
         current: 1,
         pageSize: 10
       },
       // 待审核
       pageInfoAudit: {
         storageId: "",
         supplierId: "",
         keyword: "",
         current: 1,
         pageSize: 10
       },
       // 待采购
       pageInfoProcurement: {
         storageId: "",  
         supplierId: "",
         keyword: "",
         current: 1,
         pageSize: 10
       },
       // 已处理 
       pageInfoManage: {
         storageId: "", 
         supplierId: "",
         keywords: "",
         current: 1,
         pageSize: 10
       },
       // 已作废  
       pageInfoInvalid: {
         storageId: "", 
         supplierId: "",
         keywords: "",
         current: 1,
         pageSize: 10
       },
       pageInforJected: {
         storageId: "", 
         supplierId: "",
         keywords: "",
         current: 1,
         pageSize: 10 
       },
       pageInfoGoods: {
        current: 1,
        pageSize: 10,
        keywords: ""
       },
       pageList: {
         warehouse: "",
         supplier: "",
         Number: "",
         Notes: "",  // 备注
       },
       pageInfoPurchase: {
          keywords: "",
          pageSize: 10,
          current: 1
       },
       loading: false,
       deliveryTime: "",   // 交货日期
      } 
    },
    created() {
      this.getInitInfo();
      this.find1();
    },
    computed:{
      jj() {
        let total = 0;
        this.procurementList.forEach(data=> {
          total += data.Number*data.volume
        })
        return total;
        console.log(total);
      }
    },
    methods: {
      getSummaries (param) {
        const { columns, data } = param
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计'
          } else if (index === 6) {
            const values = data.map(item => Number(item[column.property]))
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0);
            } else {
              sums[index] = 'N/A'
            }
          } else {
            sums[index] = '--'
          }
        })
        return sums
      },
      find1(){
        this.pageInfoAll.current=1
        this.Search1()
      },
      find2(){
        this.pageInfoSubmit.current=1
        this.Search2()
      },
      find3(){
        this.pageInfoAudit.current=1
        this.Search3()
      },
      find4(){
        this.pageInfoProcurement.current=1
        this.Search4()
      },
      find5(){
        this.pageInfoManage.current=1
        this.Search5()
      },
      find6(){
        this.pageInfoInvalid.current=1
        this.Search6()
      },
      find7(){
        this.pageInforJected.current=1
        this.Search7()
      },
      arraySpanMethod1({ row, column, rowIndex, columnIndex }){
        if (rowIndex % 1 === 0) {
          if (columnIndex === 2) {
            return [1, 4];
          } else {
            return [1, 1];
          }
        }
      },
      arraySpanMethod({ row, column, rowIndex, columnIndex }){
        if (rowIndex % 1 === 0) {
          if (columnIndex === 3) {
            return [1, 4];
          } else if (columnIndex === 2|| columnIndex === 1) {
            return [1, 1];
          }
        }
      },
      Close2(){
        this.packageList=[]
        this.createPurchaseVisible=false
        this.purchaseOrderVisible = false
      },
      openInfo(id,tips){
        this.$prompt('请输入备注', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue:tips,
          inputType:'textarea'
        }).then(({ value }) => {
          updataTips({id:id,tips:value}).then(res=>{
            if(res.data.code==200){
              this.$message.success(res.data.message)
              this.Search7()
              this.Search6()
              this.Search5()
              this.Search4()
              this.Search3()
              this.Search2()
              this.Search1()
            }else {
              this.$message.error(res.data.message)
              return
            }
          })
        })
      },
      amplifier(url){
        this.srcList=[url]
      },
       handleClick(tab) {
        if(tab.name == "80") {
          this.Search1()   // 全部
        }else if(tab.name == "81") {
          this.Search2();  // 待提交
        }else if(tab.name == "82") {
          this.Search3();  // 待审核
        }else if(tab.name == "83") {
          this.Search4();  // 待采购
        }else if(tab.name == "84") {
          this.Search5();  // 已处理
        }else if(tab.name == "85") {
          this.Search6();  // 已作废 
        }else if(tab.name == "86") {
          this.Search7();  // 已驳回
        }
       },
       Search1() {
         this.loading = true;
         let params = {
           current: this.pageInfoAll.current,
           pageSize: this.pageInfoAll.pageSize,
           storageId: this.pageInfoAll.storageId,
           supplierId: this.pageInfoAll.supplierId,
           keyword: this.pageInfoAll.keywords
         }
         getlistPage(params)
         .then(res=> {
           this.loading = false;
           this.orderList1 = [];
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
               this.orderList1 = res.data.values;
               this.total1 = res.data.pageInfo.total;
             }
           }
         })
       },
       handleSizeChange1(newSize) {
         this.pageInfoAll.pageSize = newSize;
         this.Search1();
       },
       handleCurrentChange1(newCurrent) {
         this.pageInfoAll.current = newCurrent;
         this.Search1();
       },
       showDetail(item) {
          getMoreDetail(item.id)
           .then(res=> {
              this.submitAuditFlag = false;
              this.purchaseAllFlag = false;
              this.processedAllFlag = false;
              this.invalidAllFlag = false;
              this.rejectedAllFlag = false;
              if(res.data.code ==200) {
                this.allDataOblect = res.data.data;
                if(res.data.data.status == 215 || res.data.status == 216) {     // 待提交, 待审核
                  this.submitAuditActive = 1  
                  this.submitAuditDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                  this.submitAuditFlag = true;
                }else if(res.data.data.status == 217) {     // 待采购
                  this.purchaseAllActive = 2
                  this.submitAuditDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                  this.purchaseAllDescription = res.data.data.examineByName + '--' + res.data.data.examineTime;
                  this.purchaseAllFlag = true;
                }else if(res.data.data.status == 218) {     // 已处理
                  this.processedAllActive = 3
                  this.submitAuditDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                  this.purchaseAllDescription = res.data.data.examineByName + '--' + res.data.data.examineTime;
                  this.processedAllDescription = res.data.data.placeByName + '--' + res.data.data.placeOrderTime;
                  this.processedAllFlag = true;
                }else if(res.data.data.status == 219) {     //  已作废
                  this.invalidAllActive = 3
                  this.submitAuditDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                  this.purchaseAllDescription = res.data.data.examineByName + '--' + res.data.data.examineTime;
                  this.invalidAllDescription = res.data.data.cancelByName + '--' + res.data.data.cancelTime;
                  this.invalidAllFlag = true;
                }else if(res.data.data.status == 220) {     //  已驳回
                  this.rejectedAllActive = 2
                  this.submitAuditDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                  this.rejectedAllDescription = res.data.data.rejectByName + '--' + res.data.data.rejectTime;
                  this.rejectedAllFlag = true;
                }
              }
           })
          this.allDataVisible = true;
       },
       Search2() {
         this.loading = true;
         let params = {
           current: this.pageInfoSubmit.current,
           pageSize: this.pageInfoSubmit.pageSize,
           storageId: this.pageInfoSubmit.storageId,
           supplierId: this.pageInfoSubmit.supplierId,
           keyword: this.pageInfoSubmit.keyword,
           status: 215
         }
         getlistPage(params)
          .then(res=> {
            this.loading = false;
            this.orderList2 = [];
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              res.data.values.forEach(item=> {
                item.purchaseProductInfoRes.forEach(item2=> {
                  item2["isChecked"] = false;
                })
              })
              this.orderList2 = res.data.values;
              this.total2 = res.data.pageInfo.total;
            }
          })
       },
       // 查询初始化
       getInitInfo() {
         getPageInit()
         .then(res=> {
           if(res.data.code == 200) {
             if(res.data.data.storage&&Array.isArray(res.data.data.storage)&&res.data.data.storage.length) {
               this.storageList = res.data.data.storage;
             }
            if(res.data.data.supplier&&Array.isArray(res.data.data.supplier)&&res.data.data.supplier.length) {
              this.supplierList = res.data.data.supplier;
            }
           }
         })
       },
       // 创建采购计划 
       add2() {
         this.selectArrLength = 0;
         this.planTips = "";  
         this.totalNumber = 0;
         this.deliveryTime = "";  // 交货日期
         this.procurementList = [];
         this.procurementVisible = true;
       },
       // 选择商品  
       chooseGoods() {
         this.goodsSearch();
         this.goodsVisible = true;
       },
       // 商品查询  
       goodsSearch() {
         let idList = [];
         this.procurementList.forEach(item=> {
            idList.push(item.id)
         })
         let params = {
          current: this.pageInfoGoods.current,
          keyword: this.pageInfoGoods.keywords,
          pageSize: this.pageInfoGoods.pageSize,
          selectedIds: idList
         }
         getProductListPage(params)
         .then(res=> {
           this.goodsLists = [];
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.goodsLists = res.data.values;
             this.totalGoods = res.data.pageInfo.total;
           }
         })
       },
       // 添加商品
       addGoods() {
         this.selectArr.forEach(item=> {
           var SelectForm = {
             productImagePath: item.productImagePath,
             commoditySku: item.commoditySku,
             id: item.id,
             weight: item.weight,
             volume: item.volume,
             storageId: "",
             supplierId: ""
           };
           this.procurementList.push(SelectForm);
         })
         this.selectArrLength = this.procurementList.length;
         this.goodsVisible = false;
       },
       checkBoxDisable(row) {
        if(row.isSelected == 1) {
          return false;
        }else {
          return true;
        }
       },
       asyncConfirm() {
          this.arrData = [];
          this.procurementList.forEach(item=> {
            var list = {
              planPurchaseNumber: item.Number,
              storageId: item.storageId,
              supplierId: item.supplierId,
              tips: item.tips,
              productId: item.id
            }
            this.arrData.push(list);
          })
          for(let i= 0; i<this.arrData.length; i++) {
            if(!this.arrData[i].planPurchaseNumber) {
              this.$message.warning('请先填写计划采购数量');
              return false;
            }
          } 
          let data = {
            purchaseProductInfoAddReqs: this.arrData,
            tips: this.planTips,
            deliveryTime: this.deliveryTime,
          }
          addPurchasePlan(data)
          .then(res=> {
            if(res.data.code == 200) {
              this.$message.success(res.data.message);
              this.procurementVisible = false;
              this.Search2();
            }else if(res.data.code == 500){
              this.$message.error(res.data.message);
            }
          })
       },
        changePurchStore(row, index) {
          let num = 0;
          this.procurementList.forEach(item=> {
            if(!item.storageId){
              num++
            }
          })
          if(num == this.procurementList.length - 1){
            this.procurementList = this.procurementList.map(data=> {
              data.storageId = row.storageId;
              return data;
            })
          }
          console.log(this.procurementList);
        },
        onClick_select(row) {
          let sum = 0;
          this.procurementList.forEach(eq=> {
            if(!eq.supplierId) {
              sum++
            }
          })
          if(sum == this.procurementList.length - 1) {
            this.procurementList = this.procurementList.map(Event=> {
              Event.supplierId = row.supplierId;
              return Event;
            })
          }
        },
       // 求和数据  
       asyncChange() {
         const arr = [];
         this.procurementList.forEach(item => {
           arr.push(item.Number)
         })
         this.totalNumber = arr.reduce((prev, cur)=> {
            return prev + cur;
         })
       },
       handleDeleteClick(index) {
         const _index =  this.procurementList.indexOf(index);
         if(_index === -1) {
            this.procurementList.splice(index, 1).length;
            this.selectArrLength = this.procurementList.length;
         }
       },
       handleSizeChangeGoods(newSize) {
         this.pageInfoGoods.pageSize = newSize;
         this.goodsSearch();
       },
       handleCurrentChangeGoods(newCurrent) {
         this.pageInfoGoods.current = newCurrent;
         this.goodsSearch();
       },
       handleSelectionGoods(arr) {
        this.selectArr = arr;
       },
       // 批量审核
       async batchAudit() {
         if(!this.currentSelectlistSubmit.length) {
           this.$message.warning('请先选择需要提交审核数据');
           return false;
         }
         const confirmResult = await this.$confirm("确定批量提交该审核吗？", "提交审核", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).catch(err => err);
          if (confirmResult !== "confirm") {
            return;
          }
          const ids = [];
          this.currentSelectlistSubmit.forEach(item=> {
            item.purchaseProductInfoRes.forEach(item2=> {
              ids.push(item2.id)
            })
          })
          let data = {
            ids: ids,
            status: 216
          }
          getBatchUpdate(data)
          .then(res=> {
            if(res.data.code == 200) {
              this.$message.success('批量审核成功');
              this.Search2();
            }else {
              this.$message.error(res.data.message)
            }
          })
       },
       // 批量删除    
       async batchDele() {
         if(!this.currentSelectlistSubmit.length) {
           this.$message.warning('请先选择需要删除数据');
           return false;
         }
         // this.orderList2.forEach(data=> {
         //   let flag = data.purchaseProductInfoRes.some(data2=> {
         //     return data2.isChecked
         //   })
         // })
         // return false;
         const confirmResult = await this.$confirm("确定批量删除该数据吗？", "提示", {
           confirmButtonText: "确定",
           cancelButtonText: "取消",
           type: "warning"
         })
         .catch(err => err);
         if (confirmResult !== "confirm") {
           return;
         }
         const ids = [];
         this.currentSelectlistSubmit.forEach(item=> {
           item.purchaseProductInfoRes.forEach(item2=> {
             ids.push(item2.id)
           })
         })
         let data = {
           ids: ids
         }
         batchDeletePlan(data)
         .then(res=> {
            if(res.data.code ==200) {
              this.$message.success(res.data.message);
              this.Search2();
            }else {
              this.$message.error(res.data.message);
            }
         }) 
       },
       handleSizeChange2(newSize) {
         this.pageInfoSubmit.pageSize = newSize;
         this.Search2();
       },
       handleCurrentChange2(newCurrent) {
         this.pageInfoSubmit.current = newCurrent;
         this.Search2();
       },
       // 提交审核   
       async submitAudit(item) {
         const confirmResult = await this.$confirm("确定提交该审核吗？", "提交审核", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).catch(err => err);
          if (confirmResult !== "confirm") {
            return;
          }
          const ids = [];
          ids.push(item.id);
          let data = {
            ids: ids,
            notPassReason: "",
            status: item.status + 1
          }
          getBatchUpdate(data)
          .then(res=> {
            if(res.data.code == 200) {
              this.$message.success(res.data.message);
              this.Search2();
            }else if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }
          })
       },
       // 编辑
       submitEdit(id) {
          getUpdateInit(id)
          .then(res=> {
             this.editDataList = [];
             if(res.data.code == 200) {
               this.editDataList = res.data.data;
               this.editDataList.forEach(data=> {
                const flag = this.storageList.some(item=> {
                  return data.storageId == item.id;
                })
                if(!flag) {
                  data.storageId = "0"
                }
               })
             }
          })
          this.editVisible = true;
       },
       // 确认编辑 
       editBtn() {
         for (let e = 0; e < this.editDataList.length; e++) {
           if(this.editDataList[e].storageId == "0") {
             this.$message.warning('采购仓库不能为已删除');
             return false;
           }
           
         }
         for(let i=0; i<this.editDataList.length; i++) {
           if(!this.editDataList[i].storageId) {
              this.$message.warning('请选择采购仓库');
              return false;
           }
         }
         for(let j=0; j<this.editDataList.length; j++) {
           if(!this.editDataList[j].planPurchaseNumber) {
             this.$message.warning('采购数量必须大于0');
             return false;
           }
         }
         for(let h=0; h<this.editDataList.length; h++) {
           if(!this.editDataList[h].supplierId) {
             this.$message.warning('请选择供应商');
             return false;
           }
         }
         let data = {
           id: this.editDataList[0].id,
           storageId: this.editDataList[0].storageId,
           planPurchaseNumber: this.editDataList[0].planPurchaseNumber,
           supplierId: this.editDataList[0].supplierId,
           tips: this.editDataList[0].tips
         }
         editPurchasePlan(data)
         .then(res=> {
           if(res.data.code == 200) {
             this.$message.success(res.data.message);
             this.editVisible = false;
             this.Search2();
           }else {
             this.$message.error(res.data.message);
           }  
         })
       },
       handleSelectionChanges2(val) {
         this.currentSelectlistSubmit = val;
         this.orderList2.forEach(item=> {
           if(val.includes(item)) {   // 勾选的时候全部选中
            item.purchaseProductInfoRes.forEach(item2=> {
              item2.isChecked = true;
            })
           }else {          //  取消的时候全部取消 
            item.purchaseProductInfoRes.forEach(item2=> {
              item2.isChecked = false;
            })
           }
         })
       },
       changeItem(id){
         console.log(id, 'klklklklklkl');
         const item = this.orderList2.find(e =>e.id == id);
         const arr = [];
         item.purchaseProductInfoRes.forEach(e=> {
           if(e.isChecked) {
             arr.push(e.id);
           }
         })
         this.$refs.orderList2.toggleRowSelection(item, arr.length === item.purchaseProductInfoRes.length);
         item.purchaseProductInfoRes.forEach(e=> {
           if(arr.includes(e.id)) {
             e.isChecked = true;
           }
         })
       },
       // 删除
       async submitDele(id) {
          const confirmResult = await this.$confirm("确定删除该数据吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).catch(err => err);
          if (confirmResult !== "confirm") {
            return;
          }
          deletePurchasePlan(id)
          .then(res=> {
            if(res.data.code == 200) {
               this.$message.success(res.data.message);
               this.Search2();
            }else {
              this.$mesage.error(res.data.message)
            }
          })
       },
       // 待提交详情
       submitDetail(id) {
         getMoreDetail(id)
         .then(res => {
           this.detailList = {};
           if(res.data.code == 200) {
             this.detailList = res.data.data;
             if(res.data.data.status == 216 || res.data.data.status == 215) {
               this.active = 1;
               this.description = res.data.data.createByName + '--' + res.data.data.createTime;
             }else if (res.data.data.status == 217) {
               this.active = 2;
             }else if(res.data.data.status == 218) {
               this.active = 3;
             }
           }
         })
         this.detailVisible = true;
       },
       // 待审核查询   
       Search3() {
         let params = {
           current: this.pageInfoAudit.current,
           pageSize: this.pageInfoAudit.pageSize,
           keyword: this.pageInfoAudit.keyword,
           storageId: this.pageInfoAudit.storageId,
           supplierId: this.pageInfoAudit.supplierId,
           status: 216
         }
         getlistPage(params)
         .then(res=> {
           this.orderList3 = [];
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
               res.data.values.forEach(item=> {
                  item.purchaseProductInfoRes.forEach(item2=> {
                    item2["isChecked"] = false;
                  })
               })
               this.orderList3 = res.data.values;
               this.total3 = res.data.pageInfo.total;
             }
           }
         })
       },
       // 批量审核通过    
       async batchPass() {
         if(!this.currentSelectlistAudit.length) {
           this.$message.warning('请选择需要通过审核数据');
           return false;
         }
         const confirmResult = await this.$confirm("确定批量通过该审核吗？", "通过审核", {
           confirmButtonText: "确定",
           cancelButtonText: "取消",
           type: "warning"
         }).catch(err => err);
         if (confirmResult !== "confirm") {
           return;
         }
         const ids = [];
         this.currentSelectlistAudit.forEach(item=> {
           item.purchaseProductInfoRes.forEach(item2=> {
             ids.push(item2.id)
           })
         })
         let data = {
           ids: ids,
           status: 217
         }
         getBatchUpdate(data)
          .then(res=> {
            if(res.data.code == 200) {
              this.$message.success('批量审核通过');
              this.Search3();
            }else {
              this.$mesage.error(res.data.message);
            }
          })
       },
       // 批量审核不通过   
       batchNotPass() {
         if(!this.currentSelectlistAudit.length) {
           this.$message.warning('请选择审核不通过数据');
           return false;
         }
         this.batchForm.reason = "";  // 数据置空
         this.batchFailureDialogVisible = true;
       },
       asyncBatchFail() {
         this.$refs.batchFormRef.validate(valid => {
           if(valid) {
            const ids = [];
            this.currentSelectlistAudit.forEach(item=> {
              item.purchaseProductInfoRes.forEach(item2=> {
                ids.push(item2.id)
              })
            })
            let data = {
              ids: ids,
              notPassReason: this.batchForm.reason,
              status: 220
            }
            getBatchUpdate(data)
              .then(res=> {
                if(res.data.code == 200) {
                  this.$message.success('批量审核不通过');
                  this.batchFailureDialogVisible = false;
                  this.Search3();
                }else if(res.data.code == 500){
                  this.$mesage.error(res.data.message);
                }
            })
           }else {
             return false;
           }
         })
       },
       handleSelectionChanges3(val) {
         this.currentSelectlistAudit = val;
         this.orderList3.forEach(item=> {
           if(val.includes(item)) {
             item.purchaseProductInfoRes.forEach(item2=> {
               item2.isChecked = true;
             })
           }else {
             item.purchaseProductInfoRes.forEach(item2=> {
               item2.isChecked = false;
             })
           }
         })
       },
       changeStatus(id) {
         const item = this.orderList3.find(e =>e.id == id);
         const arr = [];
         item.purchaseProductInfoRes.forEach(e=> {
           if(e.isChecked) {
             arr.push(e.id);
           }
         })
         this.$refs.orderList3.toggleRowSelection(item, arr.length === item.purchaseProductInfoRes.length);
         item.purchaseProductInfoRes.forEach(e=> {
           if(arr.includes(e.id)) {
             e.isChecked = true;
           }
         })
       },
       // 审核通过    
       async approved(item) {
         const confirmResult = await this.$confirm("是否确认审核该数据？", "确认审核", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
         }).catch(err => err);
          if (confirmResult !== "confirm") {
            return;
         }
         const ids = [];
         ids.push(item.id);
         let data = {
           ids: ids,
           notPassReason: "",
           status: item.status + 1
         }
         getBatchUpdate(data)
         .then(res=> {
           if(res.data.code == 200) {
             this.$message.success(res.data.message);
             this.Search3();
           }else {
             this.$message.error(res.data.message);
           }
         })

       },
       // 审核不通过   
       faileApproved(item) {
         this.notThroughId = item.id;
         this.notThroughStatus = item.status;
         this.failureDialogVisible = true;
       },
       asyncFail() {
         this.$refs.formRef.validate(valid => {
           if (valid) {
             const ids = [];
             ids.push(this.notThroughId);
             let data = {
               ids: ids,
               notPassReason: this.form.reason,
               status: 220
             }
             getBatchUpdate(data)
             .then(res=> {
               if(res.data.code == 200) {
                 this.$message.success(res.data.message);
                 this.failureDialogVisible = false;
                 this.Search3();
                 this.form.reason = "";
               }else {
                 this.$message.error(res.data.message);
               }
             })
           } else {
             return false;
           }
         })

       },
       // 审核详情   
       auditDetail(item) {
         getMoreDetail(item.id)
         .then(res=> {
           this.auditObj = {};
           if(res.data.code == 200) {
             this.auditObj = res.data.data;
             if(res.data.data.status == 216 || res.data.data.status == 215) {
               this.auditactive = 1;
               this.description = res.data.data.createByName + '--' + res.data.data.createTime;
             }else if(res.data.data.status == 217) {
               this.auditactive = 2;
             }else if(res.data.data.status == 218) {
               this.auditactive = 3;
             }
           }
         })
         this.toAuditVisible = true;
       },
       handleSizeChange3(newSize) {
         this.pageInfoAudit.pageSize = newSize;
         this.Search3();
       },
       handleCurrentChange3(newCurrent) {
         this.pageInfoAudit.current = newCurrent;
         this.Search3();
       },
       // 待采购查询
       Search4() {
         let params = {
           current: this.pageInfoProcurement.current,
           pageSize: this.pageInfoProcurement.pageSize,
           storageId: this.pageInfoProcurement.storageId,
           supplierId: this.pageInfoProcurement.supplierId,
           keyword: this.pageInfoProcurement.keyword,
           status: 217
         }
         getlistPage(params)
         .then(res=> {
           this.orderList4 = [];
           this.total4 = 0;
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             res.data.values.forEach(item=> {
               item.purchaseProductInfoRes.forEach(item2=> {
                 item2["isChecked"] = false;
               })
             })
             this.orderList4 = res.data.values;
             this.total4 = res.data.pageInfo.total;
           }
         })
       },    
       // 批量生成采购单    
       batchProcureOrder() {
         if(!this.currentSelectlist.length) {
           this.$message.warning('请选择批量生成采购单数据');
           return false;
         }
         const ids = [];
         this.currentSelectlist.forEach(item=> {
           item.purchaseProductInfoRes.forEach(item2=> {
             ids.push(item2.id)
           })
         })
         addPurchaseInit(ids)
         .then(res=> {
           this.purchaseOrderlist = [];
           if(res.data.code == 200) {
             this.purchaseOrderlist = res.data.data.data;
           }
         })
         this.purchaseOrderVisible = true;
       },
       handleSelectionChanges4(val) {
         this.currentSelectlist = val;
         this.orderList4.forEach(item=> {
           if(val.includes(item)) {
             item.purchaseProductInfoRes.forEach(item2=> {
               item2.isChecked = true;
             })
           }else {
             item.purchaseProductInfoRes.forEach(item2=> {
               item2.isChecked = false;
             })
           }
         })
       },
       changeTest(id) {
         const item = this.orderList4.find(e=> e.id == id);
         const arr = [];
         item.purchaseProductInfoRes.forEach(e=> {
           if(e.isChecked) {
             arr.push(e.id)
           }
         })
         this.$refs.orderList4.toggleRowSelection(item, arr.length === item.purchaseProductInfoRes.length)
         item.purchaseProductInfoRes.forEach(e=> {
           if(arr.includes(e.id)) {
             e.isChecked = true;
           }
         })
       },
       // 生成采购单    
       generateOrder(item) {
         const ids = [];
         ids.push(item.id);
         addPurchaseInit(ids)
          .then(res=> {
            this.purchaseOrderlist = [];
            if(res.data.code == 200) {
              this.purchaseOrderlist = res.data.data.data;
              this.purchaseOrderlist.forEach(data=> {
                const flag = this.storageList.some(data=> {
                  return data.storageId == item.id;
                })
                if(!flag) {
                  data.storageId = "0"
                }
              })
            }
          })
         this.purchaseOrderVisible = true;
       },
       handleDelete(index) {
          this.purchaseOrderlist.splice(index, 1);
       },
       // 调用新增初始化
       getAddInit() {
         getAddInit()
         .then(res=> {
           this.shareTypeList = [];
           this.transferTypeList = [];
          if(res.data.code == 200) {
            this.shareTypeList = res.data.data.freightShareTypeList
            this.transferTypeList = res.data.data.transferTypeList
          }
         })
       },
       getPurchaseAddInit() {
         this.packageList = [];
         this.purchaseOrderlist.forEach(item=> {
          let list = {
            commoditySku: item.commoditySku,
            productName: item.productName,
            planPurchaseNumber: item.planPurchaseNumber,
            actualPurchaseNumber: item.actualPurchaseNumber,
            purchaseUnitPrice: parseFloat(item.purchaseUnitPrice),
            actualSupplierId: item.actualSupplierId,
            storageId: item.storageId,
            tips: item.tips,
            id: item.id,
            productId: item.productId
          };
          this.packageList.push(list);
         })
         let data = {
          purchaseProductInfoRes: this.packageList
         }
         purchaseAddInit(data)
         .then(res=> {
           this.purchaseOrderInforList = [];
           if(res.data.code == 200) {
             this.purchaseOrderInforList = res.data.data;
             this.purchaseOrderVisible = false;  // 采购单1 隐藏
             this.packageList = [];
             this.createPurchaseVisible = true;  // 采购单2 显示
           }else if(res.data.code == 500) {
             this.$message.error(res.data.message)
           }  
         })
       },
       // 下一步
       nextBtn() {
         if(this.purchaseOrderlist.length == 0) {
           this.$message.warning('采购数量必须大于0');
           return false;
         }
         for(let k= 0; k<this.purchaseOrderlist.length; k++) {
           if(this.purchaseOrderlist[k].actualPurchaseNumber <=0) {
            this.$message.warning('实际采购数量必须大于0');
            return false;
           }
         }
         for(let i= 0; i<this.purchaseOrderlist.length; i++) {
           if(this.purchaseOrderlist[i].purchaseUnitPrice <=0) {
             this.$message.warning('采购单价必须大于0');
             return false;
           }
         }
         for(let j= 0; j<this.purchaseOrderlist.length; j++) {
           if(!this.purchaseOrderlist[j].actualSupplierId) {
             this.$message.warning('请选择供应商');
             return false;
           }
         }
         for(let s=0; s<this.purchaseOrderlist.length; s++) {
           if(!this.purchaseOrderlist[s].storageId) {
             this.$message.warning('请选择采购仓库');
             return false;
           }
         }
         this.getAddInit();
         this.getPurchaseAddInit();
       },
       //添加采购计划  
       addPurchasePlanBtn() {
         this.purchaseSearch();
         this.addPurchasVisible = true;
       },
       // 采购查询
       purchaseSearch() {
         let idSelects = [];
         this.purchaseOrderlist.forEach(item=> {
            idSelects.push(item.id)
         })
         let params = {
           current: this.pageInfoPurchase.current,
           pageSize: this.pageInfoPurchase.pageSize,
           keyword: this.pageInfoPurchase.keywords,
           selectedIds: idSelects
         }
         purchasePlanCreateList(params)
         .then(res=> {
           if(res.status == 200) {
             this.purchaseLists = [];
             if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
                this.purchaseLists = res.data.values;
                this.totalPurchase = res.data.pageInfo.total;
             }
           }
         })
       },
       handleSizeChangePurchase(newSize) {
         this.pageInfoPurchase.pageSize = newSize;
         this.purchaseSearch();
       },
       handleCurrentChangePurchase(newCurrent) {
         this.pageInfoPurchase.current = newCurrent;
         this.purchaseSearch();
       },
       handleSelectionPurchase(val) {
         this.selectArrayList = val;
       }, 
       checkBoxDisable2(row) {
         if(row.isSelected == 1) {
           return false;
         }else {
           return true;
         }
       },
       confirmAdd() {
          this.selectArrayList.forEach(k => {
             this.purchaseOrderlist.push(k);
          })
          this.addPurchasVisible = false;
       },
       clearInfo() {
         this.purchaseSearch();
       },
       // 新增采购单  
       createPurchaseBtn() {
         this.purchaseOrderInforList.forEach(item=> {
            var Array = [];
            item.purchaseProductInfoRes.forEach((item)=> {
              let object = {
                productId: item.productId,
                actualPurchaseNumber: item.actualPurchaseNumber,
                purchaseUnitPrice: item.purchaseUnitPrice,
                id: item.id
              }
              Array.push(object)
            }) 
            let obj = {
              expectArrivalTime: item.expectArrivalTime,
              freight: item.freight,
              freightShareType: item.freightShareType,
              logisticsId: item.logisticsId,
              logisticsNo: item.logisticsNo,
              otherFee: item.otherFee,
              tips: item.tips,
              storageId: item.storageId,
              supplierId: item.supplierId,
              purchaseProductInfoDos: Array
            }
           this.purchaseOrderDataList.push(obj);
         })

         addPurchase(this.purchaseOrderDataList)
          .then(res=> {
            if(res.data.code == 200) {
              this.$message.success(res.data.message);
              this.createPurchaseVisible = false;
              this.Search4();
              this.packageList = [];
            }else {
              this.$message.error(res.data.message);
            }
          })
       },
       // 作废    
       asyncInvalid(item) {
        this.invalidId = item.id;
        this.invalidDialogVisible = true;
       },
       invalidBtn() {
          this.$refs.invalidFormRef.validate(valid => {
            if (valid) {
             const ids = [];
             ids.push(this.invalidId);
             let data = {
               ids: ids,
               notPassReason: this.invalidForm.reason,
               status: 219
             }
             getBatchUpdate(data)
             .then(res=> {
               if(res.data.code == 200) {
                 this.$message.success(res.data.message);
                 this.invalidDialogVisible = false;
                 this.Search4();
                 this.invalidForm.reason = "";
               }else {
                 this.$message.error(res.data.message);
               }
             })
           } else {
             return false;
           }
         })

       },
       // 待采购详情
       procureDetail(item) {
          getMoreDetail(item.id)
           .then(res => {
             if(res.data.code == 200) {
                this.purchaseObj = res.data.data;
                if(res.data.data.status == 217) {
                  this.purchaseactive = 2;
                  this.purchasedDescription1 = res.data.data.createByName + '--' + res.data.data.createTime;
                  this.purchasedDescription2 = res.data.data.examineByName + '--' + res.data.data.examineTime
                }
             }
          })
          this.toPurchaseVisible= true;
       },  
       handleSizeChange4(newSize) {
         this.pageInfoProcurement.pageSize = newSize;
         this.Search4();
       },
       handleCurrentChange4(newCurrent) {
         this.pageInfoProcurement.current = current;
         this.Search4();
       },
       // 已处理查询  
       Search5() {
         let params = {
           current: this.pageInfoManage.current,
           pageSize: this.pageInfoManage.pageSize,
           keyword: this.pageInfoManage.keywords,
           storageId: this.pageInfoManage.storageId,
           supplierId: this.pageInfoManage.supplierId,
           status: 218
         }
         getlistPage(params)
         .then(res=> {
           this.orderList5 = [];
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.orderList5 = res.data.values;
             this.total5 = res.data.pageInfo.total;
           }
         })
       },
       // 已处理详情    
       manageDetail(item) {
        getMoreDetail(item.id)
         .then(res=> {
           if(res.data.code == 200) {
            this.ManageObject = res.data.data;
            if(res.data.data.status == 218) {
               this.Manageactive = 3;
               this.ManageDescription1 = res.data.data.createByName + '--' + res.data.data.createTime;
               this.ManageDescription2 = res.data.data.examineByName + '--' + res.data.data.examineTime
               this.ManageDescription3 = res.data.data.placeByName + '--' + res.data.data.placeOrderTime;
             }
           }
         })
        this.toManageVisible = true;
       },
       handleSizeChange5(newSize) {
         this.pageInfoManage.pageSize = newSize;
         this.Search5()
       },
       handleCurrentChange5(newCurrent) {
         this.pageInfoManage.current = newCurrent;
         this.Search5();
       },
       // 已作废查询 
       Search6() {
         let params = {
           current: this.pageInfoInvalid.current,
           pageSize: this.pageInfoInvalid.pageSize,
           storageId: this.pageInfoInvalid.storageId,
           supplierId: this.pageInfoInvalid.supplierId,
           keyword: this.pageInfoInvalid.keywords,
           status: 219
         }
         getlistPage(params)
         .then(res=> {
           this.orderList6 = [];
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
               this.orderList6 = res.data.values;
               this.total6 = res.data.pageInfo.total;
             }
           }
         })
       },
       // 作废详情    
       invalidDetail(item) {
         getMoreDetail(item.id)
         .then(res=> {
           this.InvalidObject = {};
           if(res.data.code == 200) {
             this.InvalidObject = res.data.data;
             if(res.data.data.status == 219) {
               this.Invalidactive = 3;
               this.InvalidDescription1 = res.data.data.createByName + '--' + res.data.data.createTime;
               this.InvalidDescription2 = res.data.data.examineByName + '--' + res.data.data.examineTime
               this.InvalidDescription3 = res.data.data.cancelByName + '--' + res.data.data.cancelTime;

              this.cancelReason=res.data.data.cancelReason
             }
           }
         }) 
         this.toInvalidVisible = true;
       },
       handleSizeChange6(newSize) {
         this.pageInfoInvalid.pageSize = newSize;
         this.Search6();
       },
       handleCurrentChange6(newCurrent) {
         this.pageInfoInvalid.current = newCurrent;
         this.Search6();
       },

       // 已驳回查询 
       Search7() {
         let params = {
           current: this.pageInforJected.current,
           pageSize:this.pageInforJected.pageSize,
           storageId: this.pageInforJected.storageId,
           supplierId: this.pageInforJected.supplierId,
           keyword: this.pageInforJected.keywords,
           status: 220
         }
         getlistPage(params)
          .then(res=> {
            this.orderList7 = [];
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
                this.orderList7 = res.data.values;
                this.total7 = res.data.pageInfo.total;
              }
            }
          })
       },
       // 驳回详情
       rejectedDetail(item) {
         getMoreDetail(item.id)
         .then(res=> {
           this.RejectObject = {};
           if(res.data.code == 200) {
             this.RejectObject = res.data.data;
             if(res.data.data.status == 220) {
               this.Rejectactive = 3;
               this.RejectDescription1 = res.data.data.createByName + '--' + res.data.data.createTime;
               this.RejectDescription2 = res.data.data.rejectByName + '--' + res.data.data.rejectTime;
               this.cancelReason=res.data.data.cancelReason
             }
           }
         })
         this.toRejectVisible = true;
       },
       handleSizeChange7(newSize) {
         this.pageInforJected.pageSize = newSize;
         this.Search7();
       },
       handleCurrentChange7(newCurrent) {
         this.pageInforJected.current = newCurrent,
         this.Search7();
       },

    }
  }
</script>

<style lang="scss" scoped>
.el_select {
  margin-top: 15px;
  margin-right: 15px;
}
.el-pagination {
  padding-top: 50px;
  text-align: center;
  margin-bottom: 15px;
}
.el-col-2 {
  margin-top: 8px;
}
.searchStyle {
  display: flex;
  justify-content: flex-start;
}

.look {
  width: 100%;
  height: 100%;
  padding-bottom: 20%;
}
.nav {
  width: 100%;
  text-align: left;
  padding-left: 15px;
  line-height: 45px;
}
.fn {
  width: 47%;
  height: 40px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 2%;
  text-align: center;
}
.lable {
  width: 35%;
  height: 40px;
  float: left;
  line-height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 4px 0px 0px 4px;
}
.value {
  width: 65%;
  height: 42px;
  line-height: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: left;
  padding-left: 50px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-left: none;
  color: #666666ff;
  border-radius: 0px 4px 4px 0px;
  text-align: center;
}
.dialog-footer {
  display: block;
}
.lable_div {
  display: flex;
  justify-content: flex-start;
}
.outSide {
   display: flex;
   justify-content: flex-end;
   width: 100%;
}
.Operation {
  flex: 1;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.purchaseNo {
  width: 260px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.tips {
  width: 260px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.showStatus {
  width: 260px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.storageName {
  width: 260px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.supplierName {
  width: 260px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.planPurchaseNumber {
  width: 260px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.commoditySku {
  width: 260px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.purchasePlanNo {
   width: 300px;
   height: 50px;
   text-align: center;
   line-height: 50px;
}
.showChecked {
  width: 60px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
::v-deep .el-table__expanded-cell {
   padding: 0;
   padding-left: 60px;
}
.tipsInfo{
  display: inline-block;
  max-width: 700px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
</style>